// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Authorization': getUserToken()
  }, data || {});
}

function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}
function getCommonHeaderDetailsWithTokenForApi(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'authorization': getUserTokenForApi()
  }, data || {});
}
function getUserTokenForApi() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}

const getReports = (data = {}, paginationStr) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/report?'+ paginationStr;
  let headerData = {};
  return request(
    url, 'get', getCommonHeaderDetailsWithToken(headerData), data, false
  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const deleteReport = (data, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/report';
  let headerData = {};
  console.log("in delete ==>", data);
  return request(
    url, 'delete', getCommonHeaderDetailsWithToken(headerData), data, false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const apiService = {

  getReports,
  deleteReport

};

export default apiService;
