import React, { useEffect, lazy } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import history from "../../history";
import * as squadAction from "./actions";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import settingService from "../../services/setting";
import { useForm } from "react-hook-form";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import userService from "../../services/user";
import history from "../../history";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select   from '@material-ui/core/Select';


const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const Setting = props => {

    const options = ['ethereum','polygon'];

    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const [totalCnt, setTotal] = React.useState(5);
    const [currentPageSize, setPageSize] = React.useState(5);
    const [currentPageNo, setPageNo] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [sortby, setSortBy] = React.useState([]);
    const { register, handleSubmit, reset } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [blockchainValue,setBlockChainValue] = React.useState(null);
    const classes = useStyles();
    useEffect(() => {
        (async () => {
            let token = localStorage.getItem('access_token');
            console.log("token ==>", token);
            try {
                await userService.checkToken(token);
            } catch (err) {
                if (err?.response?.status == 401) {
                    history.push("/auth/logout-page")
                }
            }
        })();
    }, []);
    useEffect(() => {
        console.log("activedata ==>", activeData);
    }, [activeData]);
    useEffect(() => {
        let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`
        if (filters.length > 0 && filters[0].value.trim().length > 0) {
            pageStr = pageStr + `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`
        }
        if (sortby.length > 0) {
            pageStr = pageStr + `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? "DESC" : "ASC"}`
        }
        reload(pageStr)
    }, [currentPageNo, currentPageSize, filters, sortby])
    const reload = async (str) => {
        setActiveData({});
        var data = await settingService.getSettings({}, str)
        setTableData(data)
    };
    const setTableData = (data) => {
        console.log("resp contact==>", data);
        if (data && data.data) {
            setData(data.data.map((prop, key) => {
                console.log("prop ==>", prop);
                return {
                    key: prop.key,
                    value: prop.value,
                    blockchain: prop.blockchain,
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={async () => {
                                    console.log("details ==>", prop);
                                    setBlockChainValue(prop.blockchain);
                                    setActiveData({});
                                    setActiveData({ ...prop });
                                    setClassicModal(true)
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit style={{ color: "#7557BB" }} />
                            </Button>{" "}
                        </div>
                    )
                };
            }));
        }
    }
    const onSubmit = async (data) => {
        console.log("data ==>", data);
        var final = new FormData();
        final.append("value", data.value);
        final.append("blockchain", blockchainValue);
        final.append("id", activeData.id);
        try {
            props.loaderActions.showLoader();
            var resp = await settingService.editSetting(final);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showSuccessMessage(true, resp.message)
            reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
            setClassicModal(false)
        } catch (err) {
            console.log("err ==>", err);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showErrorMessage(true, err.response.data.message)
        }
        console.log("create resp ==>", resp);
    }
    return (
        <>
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Settings List</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "Key",
                                        accessor: "key",
                                        disableFilters: true
                                    },
                                    {
                                        Header: "Value",
                                        accessor: "value",
                                        disableFilters: true
                                    },
                                    {
                                        Header: "Blockchain",
                                        accessor: "blockchain",
                                        disableFilters: true
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions"
                                    }
                                ]}
                                data={data}
                                total={totalCnt}
                                setPageSizeServer={setPageSize}
                                setPageNoServer={setPageNo}
                                currentPageNo={currentPageNo}
                                currentPageSize={currentPageSize}
                                filters={filters}
                                setFilters={setFilters}
                                sortby={sortby}
                                setSortBy={setSortBy}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*Edit Model Start*/}
            <Dialog
                fullWidth
                open={classicModal}
                keepMounted
                onClose={() => {
                    setClassicModal(false);
                    setActiveData({});
                }}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                className="dilogCustomScss"
            >
                <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => {
                            setClassicModal(false)
                            setActiveData({})
                        }}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Edit Settings</p>
                </DialogTitle>
                {Object.keys(activeData).length > 0 ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent>
                            <CustomInput
                                name="key"
                                inputrefs={register({ required: true })}
                                labelText="Key"
                                id="key"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: true,
                                    type: "text",
                                    autoComplete: "off",
                                    defaultValue: `${activeData.key}`,
                                }}
                            />
                            <CustomInput
                                name="value"
                                inputrefs={register({ required: true })}
                                labelText="Value"
                                id="value"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{

                                    type: "text",
                                    autoComplete: "off",
                                    defaultValue: `${activeData.value}`,
                                }}
                            />
                         
                            <InputLabel id="blockchain">Blockchain</InputLabel>
                            <Select
                                labelId="blockchain"
                                id="blockchain"
                                value={blockchainValue}
                                onChange={(event)=>{setBlockChainValue(event.target.value)}}
                                label="Blockchain"
                            >   
                                <MenuItem value="ethereum">Ethereum</MenuItem>
                                <MenuItem value="polygon">Polygon</MenuItem>
                            </Select>
                            
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                            {
                                activeData ?
                                    <Button type="submit" color="transparent">Submit</Button> : ''
                            }
                            <Button
                                onClick={() => {
                                    setClassicModal(false)
                                    setActiveData({})
                                }}
                                color="danger"
                                simple
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </form>
                ) : null}
            </Dialog>
            {/*Edit Model End*/}
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Setting);
