import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { DataGrid } from '@material-ui/data-grid';
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import moment from "moment";
import React, { lazy, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import nftService from "../../services/nft";
import userService from "../../services/user";
// import history from "../../history";
import * as squadAction from "./actions";
const ReactTable = lazy(() => import(/* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const NftContainer = props => {

    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const [blockData, setBlockData] = React.useState({});
    const [totalCnt, setTotal] = React.useState(0);
    const [currentPageSize, setPageSize] = React.useState(5);
    const [currentPageNo, setPageNo] = React.useState(0);
    const [filters, setFilters] = React.useState([]);
    const [sortby, setSortBy] = React.useState([]);
    const { register, handleSubmit, reset } = useForm();
    const { register: register2, handleSubmit: handleSubmit2, reset: reset2, formState: { errors2 } } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [classicReasonModel, setClassicReasonModal] = React.useState(false);
    const [profile_pic, setProfilePic] = React.useState(null);
    const [userHistory, setHistory] = React.useState({})
    const classes = useStyles();
    useEffect(() => {
        (async () => {
            let token = localStorage.getItem('access_token');
            try {
                await userService.checkToken(token);
            } catch (err) {
                if (err?.response?.status == 401) {
                    history.push("/auth/logout-page")
                }
            }
        })()
    }, [])
    useEffect(() => {
        let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`
        if (filters.length > 0 && filters[0].value.trim().length > 0) {
            pageStr = pageStr + `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`
        }
        if (sortby.length > 0) {
            pageStr = pageStr + `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? "DESC" : "ASC"}`
        }
        reload(pageStr)
    }, [currentPageNo, currentPageSize, filters, sortby])
    const setTableData = (data) => {
        setHistory({})
        console.log("resp ==>", data);
        if (data && data.data) {
            setTotal(data.total)
            setData(data.data.map((prop, key) => {
                console.log("prop ==>", prop);
                return {
                    blockchain : prop.blockchainDisplay,
                    token_id: prop.token,
                    title: prop.title,
                    created_by: prop.created_by.username ? prop.created_by.username : prop.created_by.public_address.substring(0, 10) + "...",
                    owned_by: prop.owned_by.username ? prop.owned_by.username : prop.owned_by.public_address.substring(0, 10) + "...",
                    price: prop.metadata.price+' '+prop.metadata.currency,
                    current_copy: prop.metadata.current_copy,
                    featured: (<div className="actions-right">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={prop.metadata.featured == 1 ? true : false}
                                    onChange={event => {
                                        console.log("prop ==>", prop);
                                       
                                            changeEmailVerified({ featured: prop.metadata.featured == 1 ? 0 : 1, token: prop.token, blockchain: prop.blockchain })
                                        
                                    }
                                    }
                                    value="checkedA"
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                    }}
                                />
                            }
                            classes={{
                                label: classes.label
                            }}
                        />
                    </div>),
                    actions: (
                        // we've added some custom button actions
                        <div className="actions-right">
                            <Button
                                justIcon
                                round
                                simple
                                onClick={async () => {
                                    console.log("details ==>", prop);
                                    var history = await nftService.getHistory({ token: prop.token })
                                    console.log("history ==>", history);
                                    var table = {}
                                    table.cols = [
                                        { field: 'col1', headerName: 'User', width: 150 },
                                        { field: 'col2', headerName: 'Operation', width: 150 },
                                        { field: 'col3', headerName: 'TokenID', width: 150 },
                                        { field: 'col4', headerName: 'Price', width: 150 },
                                        { field: 'col5', headerName: 'Date', width: 200 },
                                        { field: 'col6', headerName: 'Transaction', width: 500 },
                                    ]
                                    table.rows = history.data.map((row, i) => {
                                        return { id: i, col1: row.user.username ? row.user.username : row.user.public_address, col2: row.operation, col3: row.token, col4: row.price + " " + row.currency, col5: moment(row.created_date).format('lll'), col6: row.tx_hash }
                                    })
                                    console.log("table ==>", table)

                                    setHistory({ ...table });
                                    setActiveData({});
                                    setActiveData({ ...prop });
                                    setClassicModal(true)
                                }}
                                color="warning"
                                className="edit"
                            >
                                <Edit style={{ color: "#7557BB" }} />
                            </Button>{" "}
                        </div>
                    )
                };
            }));
        }
    }
    useEffect(() => {
        console.log("activedata ==>", activeData);
    }, [activeData])
    const reload = async (str = "") => {
        setHistory({})
        var data = await nftService.getNftList({key: "featured"}, str)
        setTableData(data)
    }
    const changeEmailVerified = async (data) => {
        props.loaderActions.showLoader();
        var resp = await nftService.editNft(data);
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message)
        reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
    }
    const changeUserStatus = async (data) => {
        let obj = {};
        console.log("blockdata ==>", blockData);
        obj.reason = data.reason;
        obj.token = blockData.token;
        obj.blockchain = blockData.blockchain;
        obj.status = blockData.status == 1 ? 0 : 1;
        props.loaderActions.showLoader();
        var resp = await nftService.editNft(obj);
        reset2({ reason: "" })
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message)
        setClassicReasonModal(false)
        reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
    }
    const onSubmit = async (data) => {
        console.log("data ==>", data);

        var final = new FormData();
        final.append("title", data.title);
        final.append("description", data.description);
        final.append("unlock_data", data.unlock_data);
        final.append("alt_text", data.alt_text);
        final.append("token", activeData.token);
        final.append("blockchain", activeData.blockchain);
        try {
            props.loaderActions.showLoader();
            var resp = await nftService.editNft(final);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showSuccessMessage(true, resp.message)
            reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
            setClassicModal(false)
        } catch (err) {
            console.log("err ==>", err);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showErrorMessage(true, err.response.data.message)
        }
        console.log("create resp ==>", resp);

    }
    return (
        <>
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem>
                </GridItem>
                {/* <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setClassicModalAdd(true)
                            setProfilePic("")
                            setActiveData({})
                            reset2()
                        }}>
                            Add User
              </Button>
                    </div>
                </GridItem> */}
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Token List</h4>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                columns={[
                                    {
                                        Header: "Blockchain",
                                        accessor: "blockchain"
                                    },
                                    {
                                        Header: "TokenID",
                                        accessor: "token_id"
                                    },
                                    {
                                        Header: "Title",
                                        accessor: "title"
                                    },
                                    {
                                        Header: "Created By",
                                        accessor: "created_by"
                                    },
                                    {
                                        Header: "Owner By",
                                        accessor: "owned_by"
                                    },
                                    {
                                        Header: "Price",
                                        accessor: "price"
                                    },
                                    {
                                        Header: "Current Copy",
                                        accessor: "current_copy"
                                    },
                                    {
                                        Header: "Featured",
                                        accessor: "featured",
                                        disableFilters: true
                                    },
                                    // {
                                    //     Header: "Actions",
                                    //     accessor: "actions"
                                    // }
                                ]}
                                data={data}
                                total={totalCnt}
                                setPageSizeServer={setPageSize}
                                setPageNoServer={setPageNo}
                                currentPageNo={currentPageNo}
                                currentPageSize={currentPageSize}
                                filters={filters}
                                setFilters={setFilters}
                                sortby={sortby}
                                setSortBy={setSortBy}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {/*Edit Model Start*/}
            <Dialog
                fullWidth
                open={classicModal}
                keepMounted
                onClose={() => setClassicModal(false)}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                className="dilogCustomScss"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicModal(false)}

                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>NFT Details</p>
                </DialogTitle>
                {Object.keys(activeData).length > 0 ? <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <CustomInput
                            name="blockchain"
                            inputrefs={register({ required: true })}
                            labelText="Blockchain"
                            id="blockchain"

                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.blockchainDisplay}`
                            }}
                        />
                        <CustomInput
                            name="token"
                            inputrefs={register({ required: true })}
                            labelText="Token ID"
                            id="token"

                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.token}`
                            }}
                        />
                        <CustomInput
                            name="title"
                            inputrefs={register({ required: true })}
                            labelText="Title"
                            id="title"

                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.title}`
                            }}
                        />
                        <CustomInput
                            name="description"
                            inputrefs={register({ required: false })}
                            labelText="Description"
                            id="description"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                rows: "3",
                                multiline: true,
                                autoComplete: "off",
                                defaultValue: `${activeData.info}`
                            }}
                        />
                        <CustomInput
                            name="category"
                            inputrefs={register({ required: false })}
                            labelText="Category"
                            id="category"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.category}`
                            }}
                        />
                        <CustomInput
                            name="price"
                            inputrefs={register({ required: false })}
                            labelText="Price"
                            id="price"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.price + " " + activeData.metadata.currency}`
                            }}
                        />
                        <CustomInput
                            name="created_by"
                            inputrefs={register({ required: false })}
                            labelText="Created By"
                            id="created_by"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.created_by.username ? activeData.created_by.username : activeData.created_by.public_address}`
                            }}
                        />
                        <CustomInput
                            name="owner_by"
                            inputrefs={register({ required: false })}
                            labelText="Owned By"
                            id="owner_by"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.owned_by.username ? activeData.owned_by.username : activeData.owned_by.public_address}`
                            }}
                        />
                        <CustomInput
                            name="total_copies"
                            inputrefs={register({ required: false })}
                            labelText="Total Copies"
                            id="total_copies"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.total_copies}`
                            }}
                        />
                        <CustomInput
                            name="current_copy"
                            inputrefs={register({ required: false })}
                            labelText="Current Copy"
                            id="current_copy"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.current_copy}`
                            }}
                        />
                        <CustomInput
                            name="is_collection"
                            inputrefs={register({ required: false })}
                            labelText="Is Collection"
                            id="is_collection"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.is_collection ? "Yes" : "No"}`
                            }}
                        />
                        <CustomInput
                            name="on_resell"
                            inputrefs={register({ required: false })}
                            labelText="On Resell"
                            id="on_resell"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.on_resell ? "Yes" : "No"}`
                            }}
                        />
                        <CustomInput
                            name="sold_out"
                            inputrefs={register({ required: false })}
                            labelText="Sold Out"
                            id="sold_out"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.sold_out ? "Yes" : "No"}`
                            }}
                        />
                        <CustomInput
                            name="is_listed"
                            inputrefs={register({ required: false })}
                            labelText="Is Listed"
                            id="is_listed"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.metadata.is_listed ? "Yes" : "No"}`
                            }}
                        />
                        <CustomInput
                            name="ipfs_hash"
                            inputrefs={register({ required: false })}
                            labelText="IPFS Hash"
                            id="ipfs_hash"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: true,
                                type: "text",
                                autoComplete: "off",
                                defaultValue: `${activeData.proof.ipfs_hash}`
                            }}
                        />
                        <CustomInput
                            name="alt_text"
                            inputrefs={register({ required: false })}
                            labelText="Alternative NFT Details"
                            id="alt_text"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                rows: "3",
                                multiline: true,
                                autoComplete: "off",
                                defaultValue: `${Array.isArray(activeData.asset) ? (activeData.asset[0].alt_text ? activeData.asset[0].alt_text : "") : (activeData.asset.alt_text ? activeData.asset.alt_text : "")}`
                            }}
                        />
                        <CustomInput
                            name="unlock_data"
                            inputrefs={register({ required: false })}
                            labelText="Unlocked Data"
                            id="unlock_data"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: "text",
                                rows: "3",
                                multiline: true,
                                autoComplete: "off",
                                defaultValue: `${activeData.proof.unlock_data ? activeData.proof.unlock_data : ""}`
                            }}
                        />
                        <h2>Assets:</h2>
                        <div>
                            {Array.isArray(activeData.asset) ?
                                activeData.asset.map((a, i) => {
                                    return a.file_type.includes("video") ? <video key={i} width="320" height="240" controls src={a.src.home_page_listings} style={{ margin: "10px" }}>
                                        Your browser does not support the video tag.
                                    </video> : (a.file_type.includes("audio") ? <audio src={a.src.home_page_listings} controls autoPlay /> : <img src={a.src.home_page_listings} alt={a.alt_text} style={{ margin: "10px" }} />)
                                })

                                : activeData.asset.file_type.includes("video") ? <video width="320" height="240" controls src={activeData.asset.src.home_page_listings}>
                                    Your browser does not support the video tag.
                                </video> : (activeData.asset.file_type.includes("audio") ? <audio src={activeData.asset.src.home_page_listings} controls autoPlay /> : <img src={activeData.asset.src.home_page_listings} alt={activeData.asset.alt_text} style={{ margin: "10px" }} />)}
                        </div>
                        <h2>History:</h2>
                        {userHistory ?
                            <div>
                                <div style={{ height: 300, width: '100%', marginBottom: "20px" }}>
                                    <DataGrid rows={userHistory.rows ? userHistory.rows : []} columns={userHistory.cols ? userHistory.cols : []} pageSize={userHistory.rows ? userHistory.rows.length : 0} />
                                </div></div>
                            : null}
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>
                        {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        }
                        <Button
                            onClick={() => setClassicModal(false)}
                            color="danger"
                            simple
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form> : null}
            </Dialog>
            {/*Edit Model End*/}
            {/*Reason Model Start*/}
            <Dialog
                fullWidth
                open={classicReasonModel}
                keepMounted
                onClose={() => setClassicReasonModal(false)}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                className="dilogCustomScss"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicReasonModal(false)}

                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Enter Reason to disable NFT</p>
                </DialogTitle>
                <form onSubmit={handleSubmit2(changeUserStatus)}>
                    <DialogContent>
                        <CustomInput
                            name="reason"
                            inputrefs={register2({ required: true })}
                            labelText="Reason"
                            id="reason"

                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: false,
                                type: "text",
                                rows: "3",
                                multiline: true,
                                autoComplete: "off"
                            }}
                        />

                        <DialogActions className={classes.modalFooter}>
                            {
                                activeData ?
                                    <Button type="submit" color="transparent">Submit</Button> : ''
                            }
                            <Button
                                onClick={() => setClassicReasonModal(false)}
                                color="danger"
                                simple
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>

            </Dialog>

        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NftContainer);
