import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Close from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import moment from 'moment';
import React, { lazy, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history';
import * as loaderActions from '../../redux/loaderAction';
import * as toastComponentAction from '../../redux/toast-componentAction';
import nftService from '../../services/nft';
import userService from '../../services/user';
import settingService from '../../services/setting';
// import history from "../../history";
import * as squadAction from './actions';
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const useStyles = makeStyles(styles);
const NftGiveaway = (props) => {
  const [data, setData] = React.useState([]);
  const [totalCnt, setTotal] = React.useState(0);
  const [currentPageSize, setPageSize] = React.useState(5);
  const [currentPageNo, setPageNo] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [sortby, setSortBy] = React.useState([]);
  const [activeData, setActiveData] = React.useState({});
  const [selectedMusic, setSelected] = React.useState({});
  const [classicModal, setClassicModal] = React.useState(false);
  const [userHistory, setHistory] = React.useState({});

  const [participentsList, setParticipentsList] = React.useState({});
  const [participentsModal, setParticipentsModal] = React.useState(false);

  const classes = useStyles();
  useEffect(() => {
    (async () => {
      let token = localStorage.getItem('access_token');
      try {
        await userService.checkToken(token);
        var settings = await settingService.getSettings({}, '');
        var selected = settings.data.find(
          (set) => set.key === 'musician_of_the_week'
        );
        console.log('setting ==>', settings, selected);
        var newObj = { id: selected.id, value: selected.value };
        // console.log("resp ==>", data);
        setSelected({ ...newObj });
      } catch (err) {
        if (err?.response?.status == 401) {
          history.push('/auth/logout-page');
        }
      }
    })();
  }, []);
  const setTableData = (data) => {
    if (data && data.data) {
      setTotal(data.total);
      var newList = data.data.map((obj) => ({
        ...obj,
        active: obj.token == selectedMusic.value,
      }));
      console.log('data.data ==>', newList);
      setData(
        newList.map((prop, key) => {
          // console.log("prop ==>", prop);
          return {
            blockchain: prop.blockchainDisplay,
            token_id: prop.token,
            title: prop.title,
            created_by: prop.created_by.username
              ? prop.created_by.username
              : prop.created_by.public_address.substring(0, 10) + '...',
            owned_by: prop.owned_by.username
              ? prop.owned_by.username
              : prop.owned_by.public_address.substring(0, 10) + '...',
            price: prop.metadata.price + ' ' + prop.metadata.currency,
            total_copies: prop.metadata.total_copies,
            is_winner_announced: prop.giveaway.winner_announced == 1 ? 'Yes' : 'No',
            participents: (
              // we've added some custom button actions
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    getParticipentsList(prop.id);
                  }}
                  color="warning"
                  className="edit"
                >
                  <VisibilityIcon style={{ color: '#7557BB' }} />
                </Button>{' '}
              </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={async () => {
                    console.log('details ==>', prop);
                    var history = await nftService.getHistory({
                      token: prop.id,
                    });
                    console.log('history ==>', history);
                    var table = {};
                    table.cols = [
                      { field: 'col1', headerName: 'User', width: 150 },
                      { field: 'col2', headerName: 'Operation', width: 150 },
                      { field: 'col3', headerName: 'TokenID', width: 150 },
                      { field: 'col4', headerName: 'Price', width: 150 },
                      { field: 'col5', headerName: 'Date', width: 200 },
                      { field: 'col6', headerName: 'Transaction', width: 500 },
                    ];
                    table.rows = history.data.map((row, i) => {
                      return {
                        id: i,
                        col1: row.user.username
                          ? row.user.username
                          : row.user.public_address,
                        col2: row.operation,
                        col3: row.token,
                        col4: row.price + ' ' + row.currency,
                        col5: moment(row.created_date).format('lll'),
                        col6: row.tx_hash,
                      };
                    });
                    console.log('table ==>', table);

                    setHistory({ ...table });
                    setActiveData({});
                    setActiveData({ ...prop });
                    setClassicModal(true);
                  }}
                  color="warning"
                  className="edit"
                >
                  <VisibilityIcon style={{ color: '#7557BB' }} />
                </Button>{' '}
              </div>
            ),
          };
        })
      );
    }
  };
  useEffect(() => {
    let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`;
    if (filters.length > 0 && filters[0].value.trim().length > 0) {
      pageStr =
        pageStr +
        `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`;
    }
    if (sortby.length > 0) {
      pageStr =
        pageStr +
        `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? 'DESC' : 'ASC'}`;
    }
    reload(pageStr);
  }, [currentPageNo, currentPageSize, filters, sortby, selectedMusic]);
  const reload = async (str = '') => {
    setHistory({});
    var data = await nftService.getGiveawayNftList({}, str);

    setTableData(data);
  };
  const getParticipentsList = async (nftID) => {
    let list = await nftService.getGiveawayParticipentsList({ nft_id: nftID });
    console.log(list,"list");
    if (list.status === 1) {
      let participentsListData = list.data;

      var table = {};
      table.cols = [
        { field: 'id', headerName: '#', width: 150 },
        { field: 'col1', headerName: 'User', width: 150 },
        { field: 'col2', headerName: 'Email', width: 250 },
        { field: 'col3', headerName: 'Public Address', width: 300 },
        { field: 'col4', headerName: 'Winners', width: 150 },
      ];
      table.rows = participentsListData.map((row, i) => {
        return {
          id: i + 1,
          col1: row.username,
          col2: row.email,
          col3: row.public_address,
          col4: row.is_winner === 1 ? 'Winner' : '-',
        };
      });
      setParticipentsList({ ...table });
      setParticipentsModal(true);
    }
  };
  return (
    <>
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Token List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                filterable
                columns={[
                  {
                    Header: 'Blockchain',
                    accessor: 'blockchain',
                  },
                  {
                    Header: 'TokenID',
                    accessor: 'token_id',
                  },
                  {
                    Header: 'Title',
                    accessor: 'title',
                  },
                  {
                    Header: 'Created By',
                    accessor: 'created_by',
                  },
                  {
                    Header: 'Owner By',
                    accessor: 'owned_by',
                  },
                  {
                    Header: 'Price',
                    accessor: 'price',
                  },
                  {
                    Header: 'No Of Winners',
                    accessor: 'total_copies',
                  },
                  {
                    Header: 'Winner Announced',
                    accessor: 'is_winner_announced',
                    disableFilters: true,
                  },
                  {
                    Header: 'Participents',
                    accessor: 'participents',
                    disableFilters: true,
                  },
                  {
                    Header: 'Details',
                    accessor: 'actions',
                  },
                ]}
                data={data}
                total={totalCnt}
                setPageSizeServer={setPageSize}
                setPageNoServer={setPageNo}
                currentPageNo={currentPageNo}
                currentPageSize={currentPageSize}
                filters={filters}
                setFilters={setFilters}
                sortby={sortby}
                setSortBy={setSortBy}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicModal}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>NFT Details</p>
        </DialogTitle>
        {Object.keys(activeData).length > 0 ? (
          <>
            <DialogContent>
              <CustomInput
                name="blockchain"
                labelText="Blockchain"
                id="blockchain"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.blockchainDisplay}`,
                }}
              />
              <CustomInput
                name="token"
                labelText="Token ID"
                id="token"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.token}`,
                }}
              />
              <CustomInput
                name="title"
                labelText="Title"
                id="title"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.title}`,
                }}
              />
              <CustomInput
                name="description"
                labelText="Description"
                id="description"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  rows: '3',
                  multiline: true,
                  autoComplete: 'off',
                  defaultValue: `${activeData.description}`,
                }}
              />
              <CustomInput
                name="category"
                labelText="Category"
                id="category"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.metadata.category}`,
                }}
              />
              <CustomInput
                name="price"
                labelText="Price"
                id="price"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.price +
                    ' ' +
                    activeData.metadata.currency
                  }`,
                }}
              />
              <CustomInput
                name="created_by"
                labelText="Created By"
                id="created_by"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.created_by.username
                      ? activeData.created_by.username
                      : activeData.created_by.public_address
                  }`,
                }}
              />
              <CustomInput
                name="owner_by"
                labelText="Owned By"
                id="owner_by"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.owned_by.username
                      ? activeData.owned_by.username
                      : activeData.owned_by.public_address
                  }`,
                }}
              />
              <CustomInput
                name="total_copies"
                labelText="Total Copies"
                id="total_copies"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.metadata.total_copies}`,
                }}
              />
              <CustomInput
                name="current_copy"
                labelText="Current Copy"
                id="current_copy"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.metadata.current_copy}`,
                }}
              />
              <CustomInput
                name="is_collection"
                labelText="Is Collection"
                id="is_collection"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.is_collection ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="on_resell"
                labelText="On Resell"
                id="on_resell"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.on_resell ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="sold_out"
                labelText="Sold Out"
                id="sold_out"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.sold_out ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="is_listed"
                labelText="Is Listed"
                id="is_listed"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.is_listed ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="ipfs_hash"
                labelText="IPFS Hash"
                id="ipfs_hash"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.proof.ipfs_link}`,
                }}
              />
              <CustomInput
                name="unlock_data"
                labelText="Unlocked Data"
                id="unlock_data"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'text',
                  rows: '3',
                  multiline: true,
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.proof.unlock_data
                      ? activeData.proof.unlock_data
                      : ''
                  }`,
                }}
              />
              <h2>Assets:</h2>
              <div>
                {Array.isArray(activeData.asset) ? (
                  activeData.asset.map((a, i) => {
                    return a.file_type.includes('video') ? (
                      <video
                        key={i}
                        width="320"
                        height="240"
                        controls
                        src={a.src.home_page_listings}
                        style={{ margin: '10px' }}
                      >
                        Your browser does not support the video tag.
                      </video>
                    ) : a.file_type.includes('audio') ? (
                      <audio
                        src={a.src.home_page_listings}
                        controls
                        autoPlay={false}
                      />
                    ) : (
                      <img
                        src={a.src.home_page_listings}
                        alt={a.alt_text}
                        style={{
                          margin: '10px',
                          objectFit: 'contain',
                          width: '200px',
                          height: '100px',
                        }}
                      />
                    );
                  })
                ) : activeData.asset.file_type.includes('video') ? (
                  <video
                    width="320"
                    height="240"
                    controls
                    src={activeData.asset.src.home_page_listings}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : activeData.asset.file_type.includes('audio') ? (
                  <audio
                    src={activeData.asset.src.home_page_listings}
                    controls
                    autoPlay
                  />
                ) : (
                  <img
                    src={activeData.asset.src.home_page_listings}
                    alt={activeData.asset.alt_text}
                    style={{
                      margin: '10px',
                      objectFit: 'contain',
                      width: '200px',
                      height: '100px',
                    }}
                  />
                )}
              </div>
              <h2>History:</h2>
              {userHistory ? (
                <div>
                  <div
                    style={{ height: 300, width: '100%', marginBottom: '20px' }}
                  >
                    <DataGrid
                      rows={userHistory.rows ? userHistory.rows : []}
                      columns={userHistory.cols ? userHistory.cols : []}
                      pageSize={userHistory.rows ? userHistory.rows.length : 0}
                    />
                  </div>
                </div>
              ) : null}
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              <Button
                onClick={() => setClassicModal(false)}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
      {/*Edit Model End*/}

      {/*Participents List Start*/}
      <Dialog
        fullWidth
        open={participentsModal}
        keepMounted
        onClose={() => setParticipentsModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setParticipentsModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Participents List</p>
        </DialogTitle>
        <>
          <DialogContent>
            {participentsList ? (
              <div>
                <div style={{ height:"500px", width: '100%', marginBottom: '20px' }}>
                  <DataGrid
                    rows={participentsList.rows ? participentsList.rows : []}
                    columns={participentsList.cols ? participentsList.cols : []}
                    pageSize={
                      participentsList.rows ? participentsList.rows.length : 0
                    }
                  />
                </div>
              </div>
            ) : null}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => setParticipentsModal(false)}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </>
      </Dialog>
      {/*Edit Model End*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NftGiveaway);
