// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'Authorization':  getUserToken()
  }, data || {});
}
function getCommonHeaderDetailsWithTokenForApi(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'authorization':  getUserTokenForApi()
  }, data || {});
}
function getUserTokenForApi() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}
function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}


const getContactList = (data = {}, paginationStr) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/contactus?' + paginationStr;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const deleteContactUs = (data, id) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/contactus';
  let headerData = {};
  console.log("in delete ==>", data);
  return request(
    url, 'delete', getCommonHeaderDetailsWithToken(headerData), data, false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const apiService = {
  getContactList,
  deleteContactUs

};

export default apiService;
