import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { DataGrid } from "@material-ui/data-grid";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import moment from "moment";
import React, { lazy, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import userService from "../../services/user";
import * as squadAction from "./actions";
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ "components/ReactTable/ReactTable.js"
  )
);
const ImageUpload = lazy(() =>
  import(
    /* webpackChunkName: "image-upload" */ "components/CustomUpload/ImageUpload.js"
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const UserContainer = (props) => {
  const [data, setData] = React.useState([]);
  const [totalCnt, setTotal] = React.useState(0);
  const [currentPageSize, setPageSize] = React.useState(5);
  const [currentPageNo, setPageNo] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [sortby, setSortBy] = React.useState([]);
  const [activeData, setActiveData] = React.useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [classicModal, setClassicModal] = React.useState(false);
  const [classicReasonModel, setClassicReasonModal] = React.useState(false);
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2, formState: { errors2 } } = useForm();
  const [profile_pic, setProfilePic] = React.useState(null);
  const [userHistory, setHistory] = React.useState({});
  const [blockData, setBlockData] = React.useState({});
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      let token = localStorage.getItem('access_token');
      console.log("token ==>", token);
      try{
        if(token){
          await userService.checkToken(token);
        }
      } catch(err){
        if(err?.response?.status == 401){
          history.push("/auth/logout-page")
        }
      }
    })();
  }, [currentPageNo, currentPageSize]);
  useEffect(() => {
    let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`
    if (filters.length > 0 && filters[0].value.trim().length > 0) {
        pageStr = pageStr + `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`
    }
    if (sortby.length > 0) {
        pageStr = pageStr + `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? "DESC" : "ASC"}`
    }
    reload(pageStr)
  }, [currentPageNo, currentPageSize, filters, sortby])
  const reload = async (str = "") => {
    setHistory({})
    var data = await userService.getUserList({}, str);
    console.log("resp ==>", data);
    setTableData(data)
}
  const setTableData = (data) => {
  
      if (data && data.data) {
        setTotal(data.total)
        setData(
          data.data.map((prop, key) => {
            // console.log("prop ==>", prop);
            return {
              public_address: prop.public_address.substring(0, 10) + "...",
              name: prop.name,
              is_verified:
                prop.is_verified == 0 ? (
                  "Not Requested"
                ) : (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={prop.is_verified == 1 ? true : false}
                        onChange={(event) =>
                          changeEmailVerified({
                            is_verified: prop.is_verified == 1 ? 3 : 1,
                            userid: prop.id,
                          })
                        }
                        value="checkedA"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                  />
                ),
              username: prop.username,
              is_email_verified: (
                <FormControlLabel
                  control={
                    <Switch
                      checked={prop.is_email_verified ? true : false}
                      onChange={(event) =>
                        changeEmailVerified({
                          is_email_verified:
                            prop.is_email_verified == 1 ? 0 : 1,
                          userid: prop.id,
                        })
                      }
                      value="checkedA"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                />
              ),
              status: (
                <FormControlLabel
                  control={
                    <Switch
                      checked={prop.status == 1 ? true : false}
                      onChange={(event) =>{

                        console.log("prop ==>", prop);
                        if (prop.status == 1) {

                            console.log("block event ==>")
                            setBlockData({});
                            setBlockData({ ...prop });
                            setClassicReasonModal(true)
                        } else {
                           
                            changeEmailVerified({
                              status: prop.status == 1 ? 0 : 1,
                              userid: prop.id,
                            })
                        }
                      }
                      }
                      value="checkedA"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                />
              ),
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <Button
                    justIcon
                    round
                    simple
                    onClick={async () => {
                      console.log("details ==>", prop);
                      var history = await userService.getHistory({
                        public_address: prop.public_address,
                      });
                      console.log("history ==>", history);
                      var table = {};
                      table.cols = [
                        { field: "col1", headerName: "Operation", width: 150 },
                        { field: "col2", headerName: "TokenID", width: 150 },
                        { field: "col3", headerName: "Price", width: 150 },
                        { field: "col4", headerName: "Date", width: 200 },
                        {
                          field: "col5",
                          headerName: "Transaction",
                          width: 500,
                        },
                      ];
                      table.rows = history.data.map((row, i) => {
                        return {
                          id: i,
                          col1: row.operation,
                          col2: row.token,
                          col3: row.price + " " + row.currency,
                          col4: moment(row.created_date).format("lll"),
                          col5: row.tx_hash,
                        };
                      });
                      console.log("table ==>", table);
                      setHistory(table);
                      setActiveData({});
                      setActiveData({ ...prop });
                      setClassicModal(true);
                    }}
                    color="warning"
                    className="edit"
                  >
                    <Edit style={{ color: "#7557BB" }} />
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
  }
  
  const changeEmailVerified = async (data) => {
    props.loaderActions.showLoader();
    var resp = await userService.editUser(data);
    props.loaderActions.hideLoader();
    props.toastComponentAction.showSuccessMessage(true, resp.message);
    reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
  };
  const changeUserStatus = async (data) => {
    let obj = {};
    console.log("blockdata ==>", blockData, data);
    obj.reason = data.reason;
    obj.userid = blockData.id;
    obj.status = blockData.status == 1 ? 0 : 1;
    props.loaderActions.showLoader();
    var resp = await userService.editUser(obj);
    reset2({ reason: "" })
    props.loaderActions.hideLoader();
    props.toastComponentAction.showSuccessMessage(true, resp.message);
    setClassicReasonModal(false)
    reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
  };
  const onSubmit = async (data) => {
    console.log("data ==>", data);
    var final = new FormData();
    final.append("name", data.name);
    final.append("username", data.username);
    final.append("email", data.email);
    final.append("bio", data.bio);
    final.append("facebook_url", data.facebook_url);
    final.append("twitter_url", data.twitter_url);
    final.append("instagram_url", data.instagram_url);
    final.append("website_url", data.website_url);
    final.append("social_follower_cnt", data.social_follower_cnt);
    final.append("userid", activeData.id);

    if (profile_pic) {
      final.append("profile", profile_pic);
    }
    try {
      props.loaderActions.showLoader();
      var resp = await userService.editUser(final);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(true, resp.message);
      reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
      setClassicModal(false);
    } catch (err) {
      console.log("err ==>", err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
    }
    console.log("create resp ==>", resp);
  };
  let website_rgex = new RegExp(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  );
  let facbookUrlCheck = new RegExp(
    /((http|https):\/\/|)(www\.|)facebook\.com\/[a-zA-Z0-9.]{1,}/g
  );
  let twitterTest = new RegExp(
    /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/
  );
  let emilRgex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    <>
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>User List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Public Address",
                    accessor: "public_address",
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                  },
                  {
                    Header: "Username",
                    accessor: "username",
                  },
                  {
                    Header: "Profile Verified",
                    accessor: "is_verified",
                    disableFilters: true
                  },
                  {
                    Header: "Email Verified",
                    accessor: "is_email_verified",
                    disableFilters: true
                  },
                  {
                    Header: "User Status",
                    accessor: "status",
                    disableFilters: true
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
                total={totalCnt}
                setPageSizeServer={setPageSize}
                setPageNoServer={setPageNo}
                currentPageNo={currentPageNo}
                currentPageSize={currentPageSize}
                filters={filters}
                setFilters={setFilters}
                sortby={sortby}
                setSortBy={setSortBy}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicModal}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>User Details</p>
        </DialogTitle>
        {Object.keys(activeData).length > 0 ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <ImageUpload
                defaultPic={activeData.profile_photo}
                avatar
                addButtonProps={{
                  color: "rose",
                  round: true,
                }}
                onChangeFile={(file) => {
                  console.log("file ==>", file);
                  setProfilePic(file);
                }}
                changeButtonProps={{
                  color: "rose",
                  round: true,
                }}
                removeButtonProps={{
                  color: "danger",
                  round: true,
                }}
                button_text="Add Squad Badge"
              />
              <CustomInput
                name="public_address"
                inputrefs={register({ required: true })}
                labelText="Public Address"
                id="public_address"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.public_address}`,
                }}
              />
              <CustomInput
                name="name"
                inputrefs={register({ required: true })}
                labelText="Name"
                id="name"
                formControlProps={{
                  fullWidth: true,
                }}
                error={errors.name}
                helperText={errors.name && "Please enter name"}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.name}`,
                }}
              />
              <CustomInput
                name="email"
                inputrefs={register({
                  required: true,
                  validate: (value) => emilRgex.test(value),
                })}
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                error={errors.email}
                helperText={errors.email ? "Please enter valid Email" : ""}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.email}`,
                }}
              />
              <CustomInput
                name="username"
                inputrefs={register({ required: true })}
                labelText="Username"
                id="username"
                formControlProps={{
                  fullWidth: true,
                }}
                error={errors.username}
                helperText={errors.username && "Please enter username"}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.username}`,
                }}
              />
              <CustomInput
                name="facebook_url"
                inputrefs={register({
                  required: false,
                  validate: (value) =>
                    value.length > 0 ? facbookUrlCheck.test(value) : true,
                })}
                labelText="Facebook URL"
                id="facebook_url"
                formControlProps={{
                  fullWidth: true,
                }}
                error={errors.facebook_url}
                helperText={
                  errors.facebook_url && "Please enter valid facebook url"
                }
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.facebook_url}`,
                }}
              />
              <CustomInput
                name="twitter_url"
                inputrefs={register({
                  required: false,
                  validate: (value) =>
                    value.length > 0 ? twitterTest.test(value) : true,
                })}
                labelText="Twitter URL"
                id="twitter_url"
                error={errors.twitter_url}
                helperText={
                  errors.twitter_url && "Please enter valid twitter url"
                }
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.twitter_url}`,
                }}
              />
              <CustomInput
                name="instagram_url"
                inputrefs={register({ required: false })}
                labelText="Instagram URL"
                id="instagram_url"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.instagram_url}`,
                }}
              />
              <CustomInput
                name="website_url"
                inputrefs={register({
                  required: false,
                  validate: (value) => {
                    return value.length > 0 ? website_rgex.test(value) : true;
                  },
                })}
                labelText="Website URL"
                id="website_url"
                error={errors.website_url}
                helperText={
                  errors.website_url && "Please enter valid website url"
                }
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.website_url}`,
                }}
              />
              <CustomInput
                name="social_follower_cnt"
                inputrefs={register({ required: false })}
                labelText="Follower Count"
                id="follower"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.social_follower_cnt}`,
                }}
              />
              <CustomInput
                name="bio"
                inputrefs={register({ required: false })}
                labelText="Bio"
                id="bio"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  rows: "3",
                  multiline: true,
                  autoComplete: "off",
                  defaultValue: `${activeData.bio}`,
                }}
              />

              <h2>History:</h2>
              {userHistory && userHistory.rows ? (
                <div>
                  <div
                    style={{ height: 300, width: "100%", marginBottom: "20px" }}
                  >
                    <DataGrid
                      rows={userHistory.rows}
                      columns={userHistory.cols}
                      pageSize={userHistory?.rows?.length}
                    />
                  </div>
                </div>
              ) : null}
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              {activeData ? (
                <Button type="submit" color="transparent">
                  Submit
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={() => setClassicModal(false)}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </form>
        ) : null}
      </Dialog>
      {/*Edit Model End*/}
       {/*Reason Model Start*/}
       <Dialog
                fullWidth
                open={classicReasonModel}
                keepMounted
                onClose={() => setClassicReasonModal(false)}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                className="dilogCustomScss"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setClassicReasonModal(false)}

                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <p>Enter Reason to disable User</p>
                </DialogTitle>
                <form onSubmit={handleSubmit2(changeUserStatus)}>
                    <DialogContent>
                        <CustomInput
                            name="reason"
                            inputrefs={register2({ required: true })}
                            labelText="Reason"
                            id="reason"

                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                disabled: false,
                                type: "text",
                                rows: "3",
                                multiline: true,
                                autoComplete: "off"
                            }}
                        />

                        <DialogActions className={classes.modalFooter}>
                            {
                                activeData ?
                                    <Button type="submit" color="transparent">Submit</Button> : ''
                            }
                            <Button
                                onClick={() => setClassicReasonModal(false)}
                                color="danger"
                                simple
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>

            </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
