import LoginPage from "containers/Login/index.js";
import ForgotPasswordPage from "containers/ForgotPassword/index.js";
import ResetPasswordPage from "containers/ResetPassword/index.js";
import LogoutPage from "containers/Logout/index.js";
import UserPage from "containers/UsersContainer/index.js";
import NftPage from "containers/Nft/index.js";
import GalleryNFTPage from "containers/GalleryNFT/index.js";
import GiveawayNFT from "containers/GiveawayNFT";
import MusicNftPage from "containers/MusicNft/index.js";
import FeaturedPage from "containers/Featured/index.js";
import ContactUs from "containers/ContactUs/index.js";
import Report from "containers/Report/index.js";
import Setting from "containers/Setting/index.js";
import Clients from "containers/Clients/index.js";
import AboutUs from "containers/AboutUs/index.js";
import bidMaster from "containers/bidMaster";
// import Privacy from "containers/Privacy/index.js";
// import Terms from "containers/Terms/index.js";
import HeaderContent from "containers/HeaderContent/index.js";
import Faq from "containers/Faq/index.js";
import PageNotFound from "components/pageNotFound.js";

var dashRoutes = [
  {
    path: "/logout-page",
    name: "",
    component: LogoutPage,
    layout: "/auth",
  },
  {
    path: "/login-page",
    name: "",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/forgot-password-page",
    name: "",
    component: ForgotPasswordPage,
    layout: "/auth",
  },
  {
    path: "/reset-password-page/:token",
    name: "",
    component: ResetPasswordPage,
    layout: "/auth",
  },
  {
    path: "/not-found",
    name: "",
    component: PageNotFound,
    layout: "/auth",
  },
  // {
  //   path: "/user-page",
  //   name: "",
  //   component: UserProfilePage,
  //   layout: "/admin",
  // },
  {
    path: "/users",
    name: "Users",
    mini: "US",
    component: UserPage,
    layout: "/admin",
  },
  {
    path: "/tokens",
    name: "Tokens",
    mini: "NFT",
    component: NftPage,
    layout: "/admin",
  },
  {
    path: "/music-tokens",
    name: "Music NFTs",
    mini: "MNFT",
    component: MusicNftPage,
    layout: "/admin",
  },
  {
    path: "/gallery-nfts",
    name: "Gallery Nfts",
    mini: "GNFT",
    component: GalleryNFTPage,
    layout: "/admin",
  },
  {
    path: "/giveaway-nfts",
    name: "Giveaway Nfts",
    mini: "GANFT",
    component: GiveawayNFT,
    layout: "/admin",
  },
  {
    path: "/featured",
    name: "Featured",
    mini: "FTD",
    component: FeaturedPage,
    layout: "/admin",
  },
  {
    path: "/contactus",
    name: "ContactUs",
    mini: "CU",
    component: ContactUs,
    layout: "/admin",
  },
  {
    path: "/report",
    name: "Report",
    mini: "RP",
    component: Report,
    layout: "/admin",
  },
  {
    path: "/client",
    name: "Clients",
    mini: "CL",
    component: Clients,
    layout: "/admin",
  },
  {
    path: "/bid-master",
    name: "Bid Master",
    mini: "BM",
    component: bidMaster,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Settings",
    mini: "ST",
    component: Setting,
    layout: "/admin",
  },
  {
    collapse: true,
    path: "/cms",
    name: "CMS",
    mini: "CMS",
    layout: "/admin",
    views: [
      // {
      //   path: "/privacy",
      //   name: "Privacy Policy",
      //   mini: "PP",
      //   component: Privacy,
      //   layout: "/admin"
      // },
      // {
      //   path: "/terms",
      //   name: "Terms Of Service",
      //   mini: "TOS",
      //   component: Terms,
      //   layout: "/admin"
      // },
      {
        path: "/aboutus",
        name: "About Us",
        mini: "AU",
        component: AboutUs,
        layout: "/admin"
      },
      {
        path: "/header",
        name: "Header",
        mini: "HD",
        component: HeaderContent,
        layout: "/admin"
      },
      {
        path: "/faq",
        name: "FAQ",
        mini: "FAQ",
        component: Faq,
        layout: "/admin"
      }
    ]
  },
];
export default dashRoutes;
