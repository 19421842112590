import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import moment from "moment";
import React, { lazy, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import reportService from "../../services/reports";
import userService from "../../services/user";
// import history from "../../history";
import * as squadAction from "./actions";
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ "components/ReactTable/ReactTable.js"
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const ReportContainer = (props) => {
  const [alert, setAlert] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [totalCnt, setTotal] = React.useState(0);
  const [currentPageSize, setPageSize] = React.useState(5);
  const [currentPageNo, setPageNo] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [sortby, setSortBy] = React.useState([]);
  const [activeData, setActiveData] = React.useState({});
  const { register, handleSubmit, reset } = useForm();
  const [classicModal, setClassicModal] = React.useState(false);
  const [profile_pic, setProfilePic] = React.useState(null);
  const [userHistory, setHistory] = React.useState({});
  const classes = useStyles();
  const classesalert = useStylesAlert();
  useEffect(() => {
    (async () => {
      let token = localStorage.getItem('access_token');
      console.log("token ==>", token);
      try{
         await userService.checkToken(token);
      } catch(err){
        if(err?.response?.status == 401){
          history.push("/auth/logout-page")
        }
      }
    })();
  }, []);
  useEffect(() => {
    console.log("activedata ==>", activeData);
  }, [activeData]);
  useEffect(() => {
    let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`
    if (filters.length > 0 && filters[0].value.trim().length > 0) {
        pageStr = pageStr + `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`
    }
    if (sortby.length > 0) {
        pageStr = pageStr + `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? "DESC" : "ASC"}`
    }
    reload(pageStr)
}, [currentPageNo, currentPageSize, filters, sortby])
  const setTableData = (data) => {
    console.log("resp contact==>", data);
      if (data && data.data) {
        setTotal(data.total)
        setData(
          data.data.map((prop, key) => {
            console.log("prop data ==>", prop);
            return {
              created_date: moment(prop.created_date).format("lll"),
              nft_title: (
                <a
                  target="_blank"
                  className="linkableLabel"
                  href={`${process.env.REACT_APP_WEB_URL}/token/0x4D7CadDa9865793D86794688C66Fa2BfD0a97b6B:${prop.nft.token_id}`}
                >
                  {prop.nft.title}
                </a>
              ),
              username: (
                <a
                  target="_blank"
                  className="linkableLabel"
                  href={`${process.env.REACT_APP_WEB_URL}/profile/${prop.user.public_address}`}
                >
                  {" "}
                  {prop.user.username
                    ? prop.user.username
                    : prop.user.public_address.substring(0, 10) + "..."}
                </a>
              ),
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  <Button
                    justIcon
                    round
                    simple
                    onClick={async () => {
                      setActiveData({ ...prop });
                      setClassicModal(true);
                    }}
                    color="warning"
                    className="edit"
                  >
                    <VisibilityIcon color="primary" />
                  </Button>{" "}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() =>
                      warningWithConfirmAndCancelMessage({ id: prop.id })
                    }
                    color="danger"
                    className="remove"
                  >
                    <Close />
                  </Button>{" "}
                </div>
              ),
            };
          })
        );
      }
  }
  const reload = async (str) => {
    setActiveData({});
    var data = await reportService.getReports({}, str);
    setTableData(data)
  };
  const onSubmitDelete = async (data) => {
    try {
      props.loaderActions.showLoader();
      var resp = await reportService.deleteReport(data, data.id);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(true, resp.message);
      reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
      hideAlert();
    } catch (err) {
      console.log("err ==>", err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
      hideAlert();
    }
    console.log("create resp ==>", resp);
  };
  const onSubmit = async (data) => {
    console.log("data ==>", data);

    var final = new FormData();
    final.append("name", data.name);
    final.append("username", data.username);
    final.append("email", data.email);
    final.append("bio", data.bio);
    final.append("facebook_url", data.facebook_url);
    final.append("twitter_url", data.twitter_url);
    final.append("instagram_url", data.instagram_url);
    final.append("website_url", data.website_url);
    final.append("social_follower_cnt", data.social_follower_cnt);
    final.append("userid", activeData.id);

    if (profile_pic) {
      final.append("profile", profile_pic);
    }
    try {
      props.loaderActions.showLoader();
      var resp = await reportService.editUser(final);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(true, resp.message);
      reload();
      setClassicModal(false);
    } catch (err) {
      console.log("err ==>", err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
    }
    console.log("create resp ==>", resp);
  };
  const warningWithConfirmAndCancelMessage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onSubmitDelete(data)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesalert.button + " " + classesalert.success}
        cancelBtnCssClass={classesalert.button + " " + classesalert.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to revert this!
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  return (
    <>
      {alert}
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
        {/* <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setClassicModalAdd(true)
                            setProfilePic("")
                            setActiveData({})
                            reset2()
                        }}>
                            Add User
              </Button>
                    </div>
                </GridItem> */}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Report List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Date",
                    accessor: "created_date",
                  },
                  {
                    Header: "NFT Title",
                    accessor: "nft_title",
                  },
                  {
                    Header: "Reported By",
                    accessor: "username",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
                total={totalCnt}
                setPageSizeServer={setPageSize}
                setPageNoServer={setPageNo}
                currentPageNo={currentPageNo}
                currentPageSize={currentPageSize}
                filters={filters}
                setFilters={setFilters}
                sortby={sortby}
                setSortBy={setSortBy}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicModal}
        keepMounted
        onClose={() => {
          setClassicModal(false);
          setActiveData({});
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setClassicModal(false)
              setActiveData({})
          }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Report Details</p>
        </DialogTitle>
        {Object.keys(activeData).length > 0 ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <CustomInput
                name="created_date"
                inputrefs={register({ required: true })}
                labelText="Date"
                id="created_date"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${moment(activeData.created_date).format(
                    "lll"
                  )}`,
                }}
              />
              <CustomInput
                name="title"
                inputrefs={register({ required: false })}
                labelText="NFT title"
                id="title"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.nft.title}`,
                }}
              />
              <CustomInput
                name="link"
                inputrefs={register({ required: false })}
                labelText="NFT Details link"
                id="link"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${process.env.REACT_APP_WEB_URL}/token/0x4D7CadDa9865793D86794688C66Fa2BfD0a97b6B:${activeData.nft.token_id}`,
                }}
              />
              <CustomInput
                name="reported_by"
                inputrefs={register({ required: false })}
                labelText="Reported By"
                id="reported_by"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${
                    activeData.user.username
                      ? activeData.user.username
                      : activeData.user.public_address
                  }`,
                }}
              />
              <CustomInput
                name="reason"
                inputrefs={register({ required: false })}
                labelText="Reason"
                id="reason"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: "text",
                  autoComplete: "off",
                  defaultValue: `${activeData.reason}`,
                }}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              {/* {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        } */}
              <Button
                 onClick={() => {
                  setClassicModal(false)
                  setActiveData({})
              }}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </form>
        ) : null}
      </Dialog>
      {/*Edit Model End*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
