// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'Authorization':  getUserToken()
  }, data || {});
}
function getCommonHeaderDetailsWithTokenForApi(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'authorization':  getUserTokenForApi()
  }, data || {});
}
function getUserTokenForApi() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}
function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}


const getNftList = (data = {}, paginationStr = "") => {
  console.log("data ==>", data);
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/list?'+ (data.key ? "key="+data.key+"&" : "") + paginationStr;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getGalleryNftList = (data = {}, paginationStr = "") => {
  console.log("data ==>", data);
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/gallery-list?'+ (data.key ? "key="+data.key+"&" : "") + paginationStr;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getGiveawayNftList = (data = {}, paginationStr = "") => {
  console.log("data ==>", data);
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/giveaway-list?'+ (data.key ? "key="+data.key+"&" : "") + paginationStr;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getMusicNftList = (data = {}, paginationStr = "") => {
  console.log("data ==>", data);
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/music-list?'+ (data.key ? "key="+data.key+"&" : "") + paginationStr;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getHistory = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/history/'+data.token;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      // console.log("history resp==>", response)
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editNft = (data = {}) => {
  console.log("data ==>", data);
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft';
  let headerData = {};
  return request(
    url,  'patch', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const deleteNFT = (data = {}) => {
  console.log("data ==>", data);
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/delete';
  let headerData = {};
  return request(
    url,  'delete', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const fetchArtistDetails = (nftID) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/artist/'+nftID;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), nftID,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const getGiveawayParticipentsList = (data = {}) => {
  console.log("data ==>", data);
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/nft/giveaway-participents-list/';
  let headerData = {};
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const apiService = {

  getNftList,
  getGalleryNftList,
  getGiveawayNftList,
  getMusicNftList,
  editNft,
  getHistory,
  deleteNFT,
  fetchArtistDetails,
  getGiveawayParticipentsList

};

export default apiService;
