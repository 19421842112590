/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment, Suspense } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import ReactDOM from 'react-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import AuthLayout from 'layouts/Auth.js';
import RtlLayout from 'layouts/RTL.js';
import AdminLayout from 'layouts/Admin.js';
import store from 'redux/store.js';
import LoaderComponent from 'components/Loader/loader.js';
import ToastComponent from 'components/toast-component.js';
import hist from 'history.js';

import 'assets/scss/material-dashboard-pro-react.scss?v=1.9.0';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  console.log('appjs library ==>', library);
  return library;
}
// const hist = createBrowserHistory();

ReactDOM.render(
  <Fragment>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store()}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Suspense fallback={<div>Loading</div>}>
            <LoaderComponent />
            <ToastComponent />
            <Router history={hist}>
              <Switch>
                <Route path="/rtl" component={RtlLayout} />
                <Route path="/auth" component={AuthLayout} />
                <Route path="/admin" component={AdminLayout} />
                <Redirect from="/" to="/admin/users" />
              </Switch>
            </Router>
          </Suspense>
        </Web3ReactProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  </Fragment>,
  document.getElementById('root')
);
