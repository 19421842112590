import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// core components
import FormControlLabel from '@material-ui/core/FormControlLabel';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { DataGrid } from '@material-ui/data-grid';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import moment from 'moment';
import React, { lazy, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history';
import * as loaderActions from '../../redux/loaderAction';
import * as toastComponentAction from '../../redux/toast-componentAction';
import nftService from '../../services/nft';
import userService from '../../services/user';
import settingService from '../../services/setting';
import Swal from 'sweetalert2';

// Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// Metamask Start
import { Contract } from '@ethersproject/contracts';
import ContractDetails from 'configs/Contract';
// import { formatUnits } from '@ethersproject/units';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  injected,
  useEagerConnect,
  useInactiveListener,
} from 'hooks/connectors';
// Metamask End

// import history from "../../history";
// import * as squadAction from "./actions";

const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const useStyles = makeStyles(styles);
const GalleryNftContainer = (props) => {
  // Metamask start
  const context = useWeb3React();
  const {
    connector,
    library,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const galleryAccount = process.env.REACT_APP_GALLERY_ACCOUNT;
  const galleryChainID = parseInt(
    process.env.REACT_APP_GALLERY_DEFAULT_CHAIN_ID
  );
  const rquiredChainID = `0x${galleryChainID.toString(16)}`;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_KEY;
  const contractABIKey = process.env.REACT_APP_CONTRACT_ABI_KEY;
  const [activatingConnector, setActivatingConnector] = React.useState();

  // Metamask End

  const [data, setData] = React.useState([]);
  const [totalCnt, setTotal] = React.useState(0);
  const [currentPageSize, setPageSize] = React.useState(5);
  const [currentPageNo, setPageNo] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [sortby, setSortBy] = React.useState([]);
  const [activeData, setActiveData] = React.useState({});
  const [selectedMusic, setSelected] = React.useState({});
  const [blockData, setBlockData] = React.useState({});
  const { register, handleSubmit, reset } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors2 },
  } = useForm();
  const {
    register: deleteNFTReason2,
    handleSubmit: handleSubmit3,
    reset: reset3,
    formState: { errors3 },
  } = useForm();
  const [classicModal, setClassicModal] = React.useState(false);
  const [classicReasonModel, setClassicReasonModal] = React.useState(false);
  const [deleteReasonModel, setDeleteReasonModal] = React.useState(false);
  const [artistDetailsModel, setArtistDetailsModel] = React.useState(false);
  const [userHistory, setHistory] = React.useState({});
  const [artistDetails, setArtistDetails] = React.useState({});
  const [payoutHistory, setPayoutHistory] = React.useState([]);
  const [finalPayout, setFinalPayout] = React.useState(0);
  const [usdPrice,setUsdPrice] = React.useState(0);
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      let token = localStorage.getItem('access_token');
      try {
        await userService.checkToken(token);
        var settings = await settingService.getSettings({}, '');
        var selected = settings.data.find(
          (set) => set.key === 'nft_of_the_week'
        );
        // console.log('setting ==>', settings, selected);
        var newObj = { id: selected.id, value: selected.value };
        // console.log("resp ==>", data);
        setSelected({ ...newObj });
      } catch (err) {
        console.log(err,"Error");
        if (err?.response?.status == 401) {
          
        }
      }
    })();
  }, []);
  const setTableData = (data) => {
    if (data && data.data) {
      setTotal(data.total);
      var newList = data.data.map((obj) => ({
        ...obj,
        active: obj.token == selectedMusic.value,
      }));
      // console.log('data.data ==>', newList);
      setData(
        newList.map((prop, key) => {
          // console.log("prop ==>", prop);
          return {
            artist: (
              <>
                <Button
                  justIcon
                  round
                  simple
                  onClick={async () => {
                    getArtistDetails(prop.id);
                  }}
                  color="success"
                  className="edit"
                >
                  <VisibilityIcon style={{ color: '#7557BB' }} />
                </Button>{' '}
              </>
            ),
            blockchain: prop.blockchainDisplay,
            token_id: prop.token,
            title: prop.title,
            created_by: prop.created_by.username
              ? prop.created_by.username
              : prop.created_by.public_address.substring(0, 10) + '...',
            owned_by: prop.owned_by.username
              ? prop.owned_by.username
              : prop.owned_by.public_address.substring(0, 10) + '...',
            price: prop.metadata.price + ' ' + prop.metadata.currency,
            current_copy: prop.metadata.current_copy,
            is_listed: prop.metadata.is_listed == 1 ? 'Yes' : 'No',
            status: (
              <>
                {prop.status !== 2 && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={prop.status == 1 ? true : false}
                        onChange={(event) => {
                          // console.log("prop ==>", prop);
                          if (prop.status == 1) {
                            // console.log('block event ==>');
                            setBlockData({});
                            setBlockData({ ...prop });
                            setClassicReasonModal(true);
                          } else {
                            changeEmailVerified({
                              status: prop.status == 1 ? 0 : 1,
                              token: prop.token,
                              blockchain: prop.blockchain,
                            });
                          }
                        }}
                        value="checkedA"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          thumb: classes.switchIcon,
                          track: classes.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                  />
                )}
              </>
            ),
            delete: (
              // we've added some custom button actions
              <div className="actions-right">
                {prop.status !== 2 &&
                prop.metadata.is_listed == 1 &&
                prop.owned_by.id == 1 ? (
                  <Button
                    justIcon
                    round
                    simple
                    color="danger"
                    className="delete"
                    onClick={() => {
                      burnNFT();
                      reset3({ reason: '' });
                      setBlockData({});
                      setBlockData({ ...prop });
                      // setDeleteReasonModal(true);
                    }}
                  >
                    {<DeleteForeverIcon style={{ color: '#7557BB' }} />}
                  </Button>
                ) : null}
              </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {prop.status === 1 && prop.metadata.is_listed == 1 ? (
                  <Button
                    justIcon
                    round
                    simple
                    color="warning"
                    className="edit"
                    onClick={() => {
                      changeSetting({
                        value: prop.token,
                        blockchain: prop.blockchain,
                      });
                    }}
                  >
                    {prop.active ? (
                      <CheckCircle style={{ color: '#7557BB' }} />
                    ) : (
                      <CheckCircleOutlineOutlinedIcon
                        style={{ color: '#7557BB' }}
                      />
                    )}
                  </Button>
                ) : null}
                <Button
                  justIcon
                  round
                  simple
                  onClick={async () => {
                    // console.log('details ==>', prop);
                    var history = await nftService.getHistory({
                      token: prop.id,
                    });
                    // console.log('history ==>', history);
                    var table = {};
                    table.cols = [
                      { field: 'col1', headerName: 'User', width: 150 },
                      { field: 'col2', headerName: 'Operation', width: 150 },
                      { field: 'col3', headerName: 'TokenID', width: 150 },
                      { field: 'col4', headerName: 'Price', width: 150 },
                      { field: 'col5', headerName: 'Date', width: 200 },
                      { field: 'col6', headerName: 'Transaction', width: 500 },
                    ];
                    table.rows = history.data.map((row, i) => {
                      return {
                        id: i,
                        col1: row.user.username
                          ? row.user.username
                          : row.user.public_address,
                        col2: row.operation,
                        col3: row.token,
                        col4: row.price + ' ' + row.currency,
                        col5: moment(row.created_date).format('lll'),
                        col6: row.tx_hash,
                      };
                    });
                    // console.log('table ==>', table);

                    setHistory({ ...table });
                    setActiveData({});
                    setActiveData({ ...prop });
                    setClassicModal(true);
                  }}
                  color="warning"
                  className="edit"
                >
                  <Edit style={{ color: '#7557BB' }} />
                </Button>{' '}
              </div>
            ),
          };
        })
      );
    }
  };
  useEffect(() => {
    let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`;
    if (filters.length > 0 && filters[0].value.trim().length > 0) {
      pageStr =
        pageStr +
        `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`;
    }
    if (sortby.length > 0) {
      pageStr =
        pageStr +
        `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? 'DESC' : 'ASC'}`;
    }
    reload(pageStr);
  }, [currentPageNo, currentPageSize, filters, sortby, selectedMusic]);
  const reload = async (str = '') => {
    setHistory({});
    var data = await nftService.getGalleryNftList({}, str);
    // console.log('resp ==>', data);
    setTableData(data);
  };
  const changeEmailVerified = async (data) => {
    props.loaderActions.showLoader();
    var resp = await nftService.editNft(data);
    props.loaderActions.hideLoader();
    props.toastComponentAction.showSuccessMessage(true, resp.message);
    reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
  };
  const changeSetting = async (data) => {
    // console.log('data ==>', data);

    var final = new FormData();
    if (data.value == selectedMusic.value) {
      final.append('value', '');
    } else {
      final.append('value', data.value);
    }
    final.append('blockchain', data.blockchain);
    final.append('id', selectedMusic.id);
    try {
      props.loaderActions.showLoader();
      var resp = await settingService.editSetting(final);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(
        true,
        'Artist Of The Week changed'
      );
      reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
      if (data.value == selectedMusic.value) {
        setSelected({ id: selectedMusic.id, value: '' });
      } else {
        setSelected({ id: selectedMusic.id, value: data.value });
      }
    } catch (err) {
      // console.log('err ==>', err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
    }
    // console.log('create resp ==>', resp);
  };

  const changeUserStatus = async (data) => {
    let obj = {};
    // console.log('blockdata ==>', blockData);
    obj.reason = data.reason;
    obj.token = blockData.token;
    obj.blockchain = blockData.blockchain;
    obj.status = blockData.status == 1 ? 0 : 1;
    props.loaderActions.showLoader();
    var resp = await nftService.editNft(obj);
    reset2({ reason: '' });
    props.loaderActions.hideLoader();
    props.toastComponentAction.showSuccessMessage(true, resp.message);
    setClassicReasonModal(false);
    reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
  };
  const onSubmit = async (data) => {
    // console.log('data ==>', data);

    var final = new FormData();
    final.append('title', data.title);
    final.append('description', data.description);
    final.append('unlock_data', data.unlock_data);
    final.append('alt_text', data.alt_text);
    final.append('token', activeData.token);
    final.append('blockchain', activeData.blockchain);
    try {
      props.loaderActions.showLoader();
      var resp = await nftService.editNft(final);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(true, resp.message);
      reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
      setClassicModal(false);
    } catch (err) {
      console.log('err ==>', err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
    }
    // console.log('create resp ==>', resp);
  };
  // metamask connect and burn NFT Start

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      // console.log('connector ==>', connector);
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);
  const getErrorMessage = (error) => {
    // console.log('error ==>', error);
    if (error instanceof NoEthereumProviderError) {
      return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
    } else if (error instanceof UnsupportedChainIdError) {
      return "You're connected to an unsupported network.";
    } else if (error instanceof UserRejectedRequestErrorInjected) {
      return 'Please authorize this website to access your Ethereum account.';
    } else {
      console.log('error ==>', error);
      return 'Wallet connection failed!';
    }
  };
  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'info',
        // title: "Hi",
        text: getErrorMessage(error),
        timer: 3500,
      });
    }
  }, [error]);

  const handleWalletConnection = async (wallet) => {
    await activate(injected);
  };

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager);

  const burnNFT = async () => {
    await Swal.fire({
      title: 'Delete NFT Confirmation',
      text: `Are you sure to delete NFT?`,
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#7557BB',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleWalletConnection();
        burnNFTProcess();
      }
    });
  };

  const burnNFTProcess = async () => {
    if (window.ethereum) {
      const metamaskAccount = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const metamaskChainID = await window.ethereum.request({
        method: 'eth_chainId',
      });
      if (!metamaskAccount[0]) {
        Swal.fire({
          icon: 'error',
          text: `Please connect your gallery nft account in metamask`,
          timer: 1500,
        });
      } else {
        let verifyGalleryNftAccountDetails = await verifyMetamskDependency(
          metamaskAccount[0],
          metamaskChainID
        );
        if (verifyGalleryNftAccountDetails) {
          setDeleteReasonModal(true);
        }
      }
    } else {
      Swal.fire({
        icon: 'error',
        text: `Please install metamask`,
        timer: 1500,
      });
      return false;
    }
  };

  const verifyMetamskDependency = async (metamaskAccount, metamaskChainID) => {
    if (galleryAccount.toLowerCase() !== metamaskAccount.toLowerCase()) {
      Swal.fire({
        icon: 'error',
        text: `Metamask account should be gallery nft account`,
        timer: 1500,
      });
      return false;
    } else if (metamaskChainID !== rquiredChainID) {
      let currentNetwork =
        galleryChainID === 80001 || galleryChainID === 137
          ? 'Ethereum'
          : 'Polygon';
      let otherNetwork =
        galleryChainID === 80001 || galleryChainID === 137
          ? 'Polygon'
          : 'Ethereum';
      await Swal.fire({
        title: 'Switch Blockchain Confirmation',
        text: `Your current metamask network is ${currentNetwork} Blockchain. In order to connect metamask on ${otherNetwork} Blockchain you need to switch your network. Please confirm to proceed.`,
        icon: 'warning',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: '#7557BB',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { ethereum } = window;
          let params = [{ chainId: rquiredChainID }];
          ethereum
            .request({
              method: 'wallet_switchEthereumChain',
              params,
            })
            .then((result) => {
              Swal.fire(
                'Blockchain Switched!',
                `You are now connected to supported network. Please try again to burn nft now.`,
                'info'
              );
              return true;
            })
            .catch((error) => {
              Swal.fire(
                'Switch Cancelled!',
                `You have cancelled request for switch to ${otherNetwork} Blockchain Network`,
                'info'
              );
              return false;
            });
        } else {
          return false;
        }
      });
      return false;
    } else {
      return true;
    }
  };

  const deleteNFT = async (data) => {
    reset3({ reason: '' });
    setBlockData({});
    setDeleteReasonModal(false);

    Swal.fire({
      icon: 'info',
      text: `Burning NFT is in progress`,
    });

    props.loaderActions.showLoader();

    let mycon = new Contract(
      ContractDetails[contractAddress],
      ContractDetails[contractABIKey],
      library.getSigner()
    );

    let tx;
    let receipt;

    try {
      tx = await mycon.burnNft(blockData.token, 1);
    } catch (error) {
      console.log(error, 'error');
      props.loaderActions.hideLoader();
      Swal.fire({
        icon: 'error',
        text: error.message,
      });
      return false;
    }

    try {
      receipt = await tx.wait();
    } catch (error) {
      console.log(error, 'error');
      props.loaderActions.hideLoader();
      Swal.fire({
        icon: 'error',
        text: error.message,
      });
      return false;
    }

    const burnedTranscation = receipt.transactionHash;

    var final = new FormData();
    final.append('reason', data.reason);
    final.append('blockchain', blockData.blockchain);
    final.append('token', blockData.token);
    final.append('tx_hash', burnedTranscation);

    try {
      var resp = await nftService.deleteNFT(final);
      props.loaderActions.hideLoader();
      // props.toastComponentAction.showSuccessMessage(
      //   true,
      //   'Nft removed successfully'
      // );
      Swal.fire({
        icon: 'success',
        text: `Nft removed successfully`,
      });
      reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
      reset3({ reason: '' });
      setBlockData({});
      setDeleteReasonModal(false);
    } catch (err) {
      console.log('err ==>', err);
      props.loaderActions.hideLoader();
      // props.toastComponentAction.showErrorMessage(
      //   true,
      //   err.response.data.message
      // );
      Swal.fire({
        icon: 'error',
        text: err.response.data.message,
      });
    }
    console.log('create resp ==>', resp);
  };

  // metamask connect and burn NFT End

  const getArtistDetails = async (nftID) => {
    const artistDetails = await nftService.fetchArtistDetails(nftID);
    if (artistDetails.status === 1) {
      setArtistDetails(artistDetails.data);
      let finalPayoutValue = 0;
      if (artistDetails?.data?.historyDetails) {
        const finalHistory = artistDetails.data.historyDetails.filter(
          (his) => his.operation === 'bought'
        );
        finalHistory.reverse();
        setPayoutHistory(finalHistory);
        finalHistory.map((his, i) => {
          let priceValue = his?.price ? his?.price : 0;
          let royalty = props?.details?.metadata?.royalty
            ? props?.details?.metadata?.royalty
            : 0;
          if (i === 0) {
            finalPayoutValue += priceValue
              ? parseFloat(priceValue) - (parseFloat(priceValue) * 2.5) / 100
              : 0;
          } else {
            finalPayoutValue +=
              priceValue && parseInt(royalty) !== 0
                ? (parseFloat(priceValue) * royalty) / 100
                : 0;
          }
        });
        setFinalPayout(finalPayoutValue);
      }
      if (artistDetails?.data?.pricemasterDetails) {
        let usdPriceValue = (artistDetails?.data?.artistDetails.currency === 'MATIC') ?  artistDetails?.data?.pricemasterDetails.MATIC : artistDetails?.data?.pricemasterDetails.ETH;
        let finalUSDPrice = parseFloat(finalPayoutValue) * parseFloat(usdPriceValue);
        setUsdPrice(finalUSDPrice)
      }
      setArtistDetailsModel(true);
    }
  };

  return (
    <>
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
        {/* <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setClassicModalAdd(true)
                            setProfilePic("")
                            setActiveData({})
                            reset2()
                        }}>
                            Add User
              </Button>
                    </div>
                </GridItem> */}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Token List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                filterable
                columns={[
                  {
                    Header: 'Blockchain',
                    accessor: 'blockchain',
                  },
                  {
                    Header: 'TokenID',
                    accessor: 'token_id',
                  },
                  {
                    Header: 'Title',
                    accessor: 'title',
                  },
                  {
                    Header: 'Created By',
                    accessor: 'created_by',
                  },
                  {
                    Header: 'Owner By',
                    accessor: 'owned_by',
                  },
                  {
                    Header: 'Price',
                    accessor: 'price',
                  },
                  {
                    Header: 'Current Copy',
                    accessor: 'current_copy',
                  },
                  {
                    Header: 'Artist',
                    accessor: 'artist',
                    disableFilters: true,
                  },
                  {
                    Header: 'Listed',
                    accessor: 'is_listed',
                    disableFilters: true,
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    disableFilters: true,
                  },
                  {
                    Header: 'Delete',
                    accessor: 'delete',
                    disableFilters: true,
                  },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                  },
                ]}
                data={data}
                total={totalCnt}
                setPageSizeServer={setPageSize}
                setPageNoServer={setPageNo}
                currentPageNo={currentPageNo}
                currentPageSize={currentPageSize}
                filters={filters}
                setFilters={setFilters}
                sortby={sortby}
                setSortBy={setSortBy}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicModal}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>NFT Details</p>
        </DialogTitle>
        {Object.keys(activeData).length > 0 ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <CustomInput
                name="blockchain"
                inputrefs={register({ required: true })}
                labelText="Blockchain"
                id="blockchain"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.blockchainDisplay}`,
                }}
              />
              <CustomInput
                name="token"
                inputrefs={register({ required: true })}
                labelText="Token ID"
                id="token"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.token}`,
                }}
              />
              <CustomInput
                name="title"
                inputrefs={register({ required: true })}
                labelText="Title"
                id="title"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: activeData.status !== 1 ? true : false,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.title}`,
                }}
              />
              <CustomInput
                name="description"
                inputrefs={register({ required: false })}
                labelText="Description"
                id="description"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: activeData.status !== 1 ? true : false,
                  type: 'text',
                  rows: '3',
                  multiline: true,
                  autoComplete: 'off',
                  defaultValue: `${activeData.description}`,
                }}
              />
              <CustomInput
                name="category"
                inputrefs={register({ required: false })}
                labelText="Category"
                id="category"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.metadata.category}`,
                }}
              />
              <CustomInput
                name="price"
                inputrefs={register({ required: false })}
                labelText="Price"
                id="price"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.price +
                    ' ' +
                    activeData.metadata.currency
                  }`,
                }}
              />
              <CustomInput
                name="created_by"
                inputrefs={register({ required: false })}
                labelText="Created By"
                id="created_by"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.created_by.username
                      ? activeData.created_by.username
                      : activeData.created_by.public_address
                  }`,
                }}
              />
              <CustomInput
                name="owner_by"
                inputrefs={register({ required: false })}
                labelText="Owned By"
                id="owner_by"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.owned_by.username
                      ? activeData.owned_by.username
                      : activeData.owned_by.public_address
                  }`,
                }}
              />
              <CustomInput
                name="total_copies"
                inputrefs={register({ required: false })}
                labelText="Total Copies"
                id="total_copies"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.metadata.total_copies}`,
                }}
              />
              <CustomInput
                name="current_copy"
                inputrefs={register({ required: false })}
                labelText="Current Copy"
                id="current_copy"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.metadata.current_copy}`,
                }}
              />
              <CustomInput
                name="is_collection"
                inputrefs={register({ required: false })}
                labelText="Is Collection"
                id="is_collection"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.is_collection ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="on_resell"
                inputrefs={register({ required: false })}
                labelText="On Resell"
                id="on_resell"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.on_resell ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="sold_out"
                inputrefs={register({ required: false })}
                labelText="Sold Out"
                id="sold_out"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.sold_out ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="is_listed"
                inputrefs={register({ required: false })}
                labelText="Is Listed"
                id="is_listed"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.metadata.is_listed ? 'Yes' : 'No'
                  }`,
                }}
              />
              <CustomInput
                name="ipfs_hash"
                inputrefs={register({ required: false })}
                labelText="IPFS Hash"
                id="ipfs_hash"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  disabled: true,
                  type: 'text',
                  autoComplete: 'off',
                  defaultValue: `${activeData.proof.ipfs_link}`,
                }}
              />
              <CustomInput
                name="unlock_data"
                inputrefs={register({ required: false })}
                labelText="Unlocked Data"
                id="unlock_data"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'text',
                  rows: '3',
                  multiline: true,
                  autoComplete: 'off',
                  defaultValue: `${
                    activeData.proof.unlock_data
                      ? activeData.proof.unlock_data
                      : ''
                  }`,
                }}
              />
              <h2>Assets:</h2>
              <div>
                {Array.isArray(activeData.asset) ? (
                  activeData.asset.map((a, i) => {
                    return a.file_type.includes('video') ? (
                      <video
                        key={i}
                        width="320"
                        height="240"
                        controls
                        src={a.src.home_page_listings}
                        style={{ margin: '10px' }}
                      >
                        Your browser does not support the video tag.
                      </video>
                    ) : a.file_type.includes('audio') ? (
                      <audio src={a.src.home_page_listings} controls autoPlay />
                    ) : (
                      <img
                        src={a.src.home_page_listings}
                        alt={a.alt_text}
                        style={{
                          margin: '10px',
                          objectFit: 'contain',
                          width: '200px',
                          height: '100px',
                        }}
                      />
                    );
                  })
                ) : activeData.asset.file_type.includes('video') ? (
                  <video
                    width="320"
                    height="240"
                    controls
                    src={activeData.asset.src.home_page_listings}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : activeData.asset.file_type.includes('audio') ? (
                  <audio
                    src={activeData.asset.src.home_page_listings}
                    controls
                    autoPlay
                  />
                ) : (
                  <img
                    src={activeData.asset.src.home_page_listings}
                    alt={activeData.asset.alt_text}
                    style={{
                      margin: '10px',
                      objectFit: 'contain',
                      width: '200px',
                      height: '100px',
                    }}
                  />
                )}
              </div>
              <h2>History:</h2>
              {userHistory ? (
                <div>
                  <div
                    style={{ height: 300, width: '100%', marginBottom: '20px' }}
                  >
                    <DataGrid
                      rows={userHistory.rows ? userHistory.rows : []}
                      columns={userHistory.cols ? userHistory.cols : []}
                      pageSize={userHistory.rows ? userHistory.rows.length : 0}
                    />
                  </div>
                </div>
              ) : null}
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              {activeData && activeData.status !== 2 ? (
                <Button type="submit" color="transparent">
                  Submit
                </Button>
              ) : (
                ''
              )}
              <Button
                onClick={() => setClassicModal(false)}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </form>
        ) : null}
      </Dialog>
      {/*Edit Model End*/}
      {/*Reason Model Start*/}
      <Dialog
        fullWidth
        open={classicReasonModel}
        keepMounted
        onClose={() => setClassicReasonModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicReasonModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Enter Reason to disable NFT</p>
        </DialogTitle>
        <form onSubmit={handleSubmit2(changeUserStatus)}>
          <DialogContent>
            <CustomInput
              name="reason"
              inputrefs={register2({ required: true })}
              labelText="Reason"
              id="reason"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: false,
                type: 'text',
                rows: '3',
                multiline: true,
                autoComplete: 'off',
              }}
            />

            <DialogActions className={classes.modalFooter}>
              {activeData ? (
                <Button type="submit" color="transparent">
                  Submit
                </Button>
              ) : (
                ''
              )}
              <Button
                onClick={() => setClassicReasonModal(false)}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
      {/*Reason Model End*/}
      {/*Delete Model Start*/}
      <Dialog
        fullWidth
        open={deleteReasonModel}
        keepMounted
        onClose={() => setDeleteReasonModal(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setDeleteReasonModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Enter Reason to Delete NFT</p>
        </DialogTitle>
        <form onSubmit={handleSubmit3(deleteNFT)}>
          <DialogContent>
            <CustomInput
              name="reason"
              inputrefs={deleteNFTReason2({ required: true })}
              labelText="Reason"
              id="reason"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: false,
                type: 'text',
                rows: '3',
                multiline: true,
                autoComplete: 'off',
              }}
            />

            <DialogActions className={classes.modalFooter}>
              {activeData ? (
                <Button type="submit" color="transparent">
                  Submit
                </Button>
              ) : (
                ''
              )}
              <Button
                onClick={() => setDeleteReasonModal(false)}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
      {/*Reason Model End*/}
      {/*Artist Details Model Start*/}
      <Dialog
        fullWidth
        open={artistDetailsModel}
        keepMounted
        onClose={() => setArtistDetailsModel(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setArtistDetailsModel(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Artist Details</p>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>
                  {artistDetails?.artistDetails?.artist_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{artistDetails?.artistDetails?.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone</TableCell>
                <TableCell>{artistDetails?.artistDetails?.phone}</TableCell>
              </TableRow>
              {artistDetails?.payoutDetails && (
                <>
                  <TableRow>
                    <TableCell colSpan={2}>Payout Details</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payout Type</TableCell>
                    <TableCell>
                      {artistDetails?.payoutDetails?.payout_type === 1
                        ? 'Bank'
                        : 'Wallet'}
                    </TableCell>
                  </TableRow>
                  {artistDetails?.payoutDetails?.payout_type === 2 && (
                    <>
                      <TableRow>
                        <TableCell>Wallet Address</TableCell>
                        <TableCell>
                          {artistDetails?.payoutDetails?.wallet_address}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {artistDetails?.payoutDetails?.payout_type === 1 && (
                    <>
                      <TableRow>
                        <TableCell>Bank Name</TableCell>
                        <TableCell>
                          {artistDetails?.payoutDetails?.bank_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Account Number</TableCell>
                        <TableCell>
                          {artistDetails?.payoutDetails?.account_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Account Holder Name</TableCell>
                        <TableCell>
                          {artistDetails?.payoutDetails?.account_holder_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>BIC/SWIFT/IFSC</TableCell>
                        <TableCell>
                          {artistDetails?.payoutDetails?.bank_code}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Currency</TableCell>
                        <TableCell>
                          {artistDetails?.payoutDetails?.currency}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </>
              )}
            </Table>
          </TableContainer>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7}>Payout Calulation</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Operation</TableCell>
                  <TableCell>
                    NFT Price {`(${artistDetails?.artistDetails?.currency})`}
                  </TableCell>
                  <TableCell>NFT Royalty (%)</TableCell>
                  <TableCell>
                    NFT Royalty {`(${artistDetails?.artistDetails?.currency})`}
                  </TableCell>
                  <TableCell>Marketplace Service Fee (%)</TableCell>
                  <TableCell>
                    Paid By The Buyer{' '}
                    {`(${artistDetails?.artistDetails?.currency})`}
                  </TableCell>
                  <TableCell>
                    To Be Paid To The Artist{' '}
                    {`(${artistDetails?.artistDetails?.currency})`}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payoutHistory.map((his, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {i === 0 ? his.operation.toUpperCase() : 'RESOLD'}
                    </TableCell>
                    <TableCell>{his.price}</TableCell>
                    <TableCell>
                      {i === 0 ? 0 : artistDetails?.artistDetails?.royalty}
                    </TableCell>
                    <TableCell>
                      {i === 0
                        ? 0
                        : (
                            (parseFloat(his.price) *
                              artistDetails?.artistDetails?.royalty) /
                            100
                          ).toFixed(4)}
                    </TableCell>
                    <TableCell>2.5</TableCell>
                    <TableCell>
                      {i === 0
                        ? (
                            parseFloat(his.price) -
                            (parseFloat(his.price) * 2.5) / 100
                          ).toFixed(5)
                        : (
                            parseFloat(his.price) -
                            (parseFloat(his.price) * 2.5) / 100 -
                            (parseFloat(his.price) *
                              artistDetails?.artistDetails?.royalty) /
                              100
                          ).toFixed(5)}
                    </TableCell>
                    <TableCell>
                      {i === 0 ? (
                        <>
                          {(
                            parseFloat(his.price) -
                            (parseFloat(his.price) * 2.5) / 100
                          ).toFixed(5)}{' '}
                        </>
                      ) : (
                        (
                          (parseFloat(his.price) *
                            artistDetails?.artistDetails?.royalty) /
                          100
                        ).toFixed(4)
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <b>
                      Final Payout{' '}
                      {`(${artistDetails?.artistDetails?.currency})`}
                    </b>
                  </TableCell>
                  <TableCell colSpan={6}>
                    {payoutHistory.length == 0 ? 0 : finalPayout.toFixed(5)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Final Payout (USD)</b>
                  </TableCell>
                  <TableCell colSpan={6}>
                    {payoutHistory.length == 0 ? 0 : usdPrice.toFixed(5)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      {/*Payout Details Model End*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GalleryNftContainer);
