import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import moment from 'moment';
import React, { lazy, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loaderActions from '../../redux/loaderAction';
import * as toastComponentAction from '../../redux/toast-componentAction';
import userService from '../../services/user';
import { DateTimePicker } from '@material-ui/pickers';
// import history from "../../history";
import * as squadAction from './actions';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  selectCustom: {
    font: 'inherit',
    color: 'currentColor',
    width: '100%',
    border: 0,
    height: '1.1876em',
    margin: 0,
    display: 'block',
    padding: '6px 0 7px',
    minWidth: 0,
    background: 'none',
    boxSizing: 'content-box',
    animationName: 'mui-auto-fill-cancel',
    letterSpacing: 'inherit',
    animationDuration: '10ms',
    opacity: 1,
    fontSize: '16px',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontWeight: 400,
    lineHeight: '1.42857',
    borderColor: '#7557BB',
    transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    borderBottom: '2px solid #D2D2D2',
    '&:focus,&:active': {
      borderBottom: '2px solid #3f51b5 !important',
    },
  },
};

const useStyles = makeStyles(styles);
const BidMaster = (props) => {
  const [data, setData] = React.useState([]);
  const [totalCnt, setTotal] = React.useState(0);
  const [currentPageSize, setPageSize] = React.useState(5);
  const [currentPageNo, setPageNo] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [sortby, setSortBy] = React.useState([]);
  const [masterBidTableData, setMasterBidTableData] = React.useState([]);
  const [bidUsersTableData, setBidUsersTableData] = React.useState([]);
  const [editMode, setEditMode] = React.useState(1);
  const [classicModalAdd, setClassicModalAdd] = React.useState(false);
  const [bidDetailsModelView, setBidDetailsModelView] = React.useState(false);
  const defaultFormData = {
    page_type: 1,
    start_time: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
    end_time: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
    min_date_time: moment(new Date()).format('YYYY-MM-DDT00:00'),
    bid_master_id: null,
    is_completed: 0,
  };
  const [formData, setFormData] = React.useState(defaultFormData);
  const { handleSubmit } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors2 },
  } = useForm();
  const classes = useStyles();
  const setTableData = (data) => {
    if (data && data.data) {
      setTotal(data.total);
      var newList = data.data;
      setData(
        newList.map((prop, key) => {
          return {
            title: prop.page_type === 1 ? 'Home Page' : 'Explore Page',
            start_time: moment(prop.start_time, 'x').format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            end_time: moment(prop.end_time, 'x').format('YYYY-MM-DD HH:mm:ss'),
            is_completed:
              parseInt(prop.is_completed) === 1
                ? 'Completed'
                : parseInt(prop.is_completed) === 2
                ? 'Running'
                : 'Yet to start',
            view_details: (
              // we've added some custom button actions
              <div>
                {prop.is_completed !== 0 && (
                  <>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={async () => {
                        getBidDetails(prop.id);
                      }}
                      color="warning"
                      className="edit"
                    >
                      <VisibilityIcon style={{ color: '#7557BB' }} />
                    </Button>{' '}
                  </>
                )}
              </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {prop.is_completed === 1 ? (
                  ''
                ) : (
                  <>
                    <Button
                      justIcon
                      round
                      simple
                      onClick={async () => {
                        setEditModeData(prop);
                      }}
                      color="warning"
                      className="edit"
                    >
                      <Edit style={{ color: '#7557BB' }} />
                    </Button>{' '}
                  </>
                )}
              </div>
            ),
          };
        })
      );
    }
  };
  const setEditModeData = async (data) => {
    let currentTimeStamp = moment(new Date()).format('x');
    if (parseInt(currentTimeStamp) > parseInt(data.start_time)) {
      setEditMode(2);
    }
    if (parseInt(currentTimeStamp) > parseInt(data.end_time)) {
      setEditMode(3);
    }
    let minDate = formData.min_date_time;
    let minDateTimeStamp = moment(minDate).format('x');
    if (parseInt(minDateTimeStamp) > parseInt(data.start_time)) {
      minDate = moment(data.start_time, 'x').format('YYYY-MM-DDT00:00');
    }
    setFormData({
      ...formData,
      page_type: data.page_type,
      start_time: moment(data.start_time, 'x').format('YYYY-MM-DDTHH:mm'),
      end_time: moment(data.end_time, 'x').format('YYYY-MM-DDTHH:mm'),
      min_date_time: minDate,
      bid_master_id: data.id,
    });
    setClassicModalAdd(true);
  };
  useEffect(() => {
    let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`;
    if (filters.length > 0 && filters[0].value.trim().length > 0) {
      pageStr =
        pageStr +
        `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`;
    }
    if (sortby.length > 0) {
      pageStr =
        pageStr +
        `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? 'DESC' : 'ASC'}`;
    }
    reload(pageStr);
  }, [currentPageNo, currentPageSize, filters, sortby]);
  const reload = async (str = '') => {
    var data = await userService.getBidList({}, str);
    setTableData(data);
  };
  const onSubmit = async (data) => {
    const page_type = formData.page_type;
    const start_time = formData.start_time;
    const end_time = formData.end_time;
    let isValid = 1;
    if (!page_type) {
      props.toastComponentAction.showErrorMessage(
        true,
        'Please select page type'
      );
      isValid = 2;
    }
    if (!start_time) {
      props.toastComponentAction.showErrorMessage(
        true,
        'Please select start time'
      );
      isValid = 2;
    }
    if (!end_time) {
      props.toastComponentAction.showErrorMessage(
        true,
        'Please select end time'
      );
      isValid = 2;
    }
    if (start_time && end_time) {
      let startTimeTimeStamp = moment(start_time).format('x');
      let endTimeTimeStamp = moment(end_time).format('x');
      if (parseInt(startTimeTimeStamp) >= parseInt(endTimeTimeStamp)) {
        props.toastComponentAction.showErrorMessage(
          true,
          'Start time should be greater than end time'
        );
        isValid = 2;
      }
    }
    if (isValid === 1) {
      let currentTimeStamp = moment(new Date()).format('x');
      let startTime = moment(start_time).format('x');
      let endTime = moment(end_time).format('x');
      let is_completed = 0;
      if (parseInt(currentTimeStamp) > parseInt(endTime)) {
        is_completed = 1;
      } else if (parseInt(currentTimeStamp) > parseInt(startTime)) {
        is_completed = 2;
      }
      const reqData = {
        page_type: page_type,
        start_time: startTime,
        end_time: endTime,
        bid_master_id: formData.bid_master_id,
        is_completed: is_completed,
      };
      try {
        props.loaderActions.showLoader();
        var resp = await userService.createBid(reqData);
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message);
        reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
        setClassicModalAdd(false);
        setFormData(defaultFormData);
        setEditMode(1);
      } catch (err) {
        props.loaderActions.hideLoader();
        props.toastComponentAction.showErrorMessage(
          true,
          err.response.data.message
        );
      }
    }
  };
  const getBidDetails = async (bidID) => {
    console.log(bidID, 'bidID');
    const bidDetailsData = await userService.getBidDetails({
      bid_master_id: bidID,
    });
    if (bidDetailsData.status === 1) {
      console.log(bidDetailsData.data, 'bidDetailsData');
      let bidMasterData = bidDetailsData.data.bid_master_details;
      // let bidUsersData = bidDetailsData.data.bid_records;
      setMasterBidTableData(bidMasterData);
      // setBidUsersTableData(bidUsersData);
      setBidDetailsModelView(true);
    }
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      padding: '5px',
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <>
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
        <GridItem>
          <div>
            <Button
              color="primary"
              className={classes.marginRight}
              onClick={(e) => {
                setClassicModalAdd(true);
              }}
            >
              Add Spotlight Bid
            </Button>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Spotlight Bid List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                filterable
                columns={[
                  {
                    Header: 'Page Type',
                    accessor: 'title',
                  },
                  {
                    Header: 'Start Time',
                    accessor: 'start_time',
                    disableFilters: true,
                  },
                  {
                    Header: 'End Time',
                    accessor: 'end_time',
                    disableFilters: true,
                  },
                  {
                    Header: 'Status',
                    accessor: 'is_completed',
                    disableFilters: true,
                  },
                  {
                    Header: 'View Bid',
                    accessor: 'view_details',
                    disableFilters: true,
                  },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                  },
                ]}
                data={data}
                total={totalCnt}
                setPageSizeServer={setPageSize}
                setPageNoServer={setPageNo}
                currentPageNo={currentPageNo}
                currentPageSize={currentPageSize}
                filters={filters}
                setFilters={setFilters}
                sortby={sortby}
                setSortBy={setSortBy}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Add Model Start*/}
      <Dialog
        fullWidth
        open={classicModalAdd}
        keepMounted
        onClose={() => {
          setClassicModalAdd(false);
          setFormData(defaultFormData);
          setEditMode(1);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModalAdd(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Spotlight Details</p>
        </DialogTitle>
        <form
          id="custom-form"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogContent>
            <label>Page Type</label>
            <select
              onChange={(event) => {
                setFormData({
                  ...formData,
                  page_type: event.target.value,
                });
              }}
              className={classes.selectCustom}
              disabled={editMode === 1 ? false : true}
            >
              <option
                selected={formData.page_type === 1 ? true : false}
                value={1}
              >
                Home Page
              </option>
              <option
                selected={formData.page_type === 2 ? true : false}
                value={2}
              >
                Explore Page
              </option>
            </select>
          </DialogContent>
          <DialogContent>
            <label>Start Time</label>
            <br />
            <DateTimePicker
              className={styles.dateTimeInput}
              placeholder="Start Date & Time"
              inputVariant="outlined"
              minDate={new Date(formData.min_date_time)}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  start_time: value,
                });
              }}
              value={formData.start_time}
              disabled={editMode === 1 ? false : true}
            />
          </DialogContent>
          <DialogContent>
            <label>End Time</label>
            <br />
            <DateTimePicker
              className={styles.dateTimeInput}
              placeholder="End Date & Time"
              inputVariant="outlined"
              minDate={new Date(formData.min_date_time)}
              onChange={(value) => {
                setFormData({
                  ...formData,
                  end_time: value,
                });
              }}
              value={formData.end_time}
              disabled={editMode !== 3 ? false : true}
            />
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button type="submit" color="transparent">
              Submit
            </Button>
            <Button
              onClick={() => {
                setClassicModalAdd(false);
                setFormData(defaultFormData);
                setEditMode(1);
              }}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/*Add Model End*/}
      {/*Bid Details Model Start*/}
      <Dialog
        fullWidth
        open={bidDetailsModelView}
        keepMounted
        onClose={() => {
          setBidDetailsModelView(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setBidDetailsModelView(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Spotlight Bid Details</p>
        </DialogTitle>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Spotlight Bid List</h4>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper}>
              <Table
                sx={{ width: '100%', overflow: 'hidden' }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Position</TableCell>
                    <TableCell>Highest Bid</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Selected NFT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {masterBidTableData.map((row) => (
                    <StyledTableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.position}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.bid}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.user?.username
                          ? row?.user?.username
                          : row?.user?.public_address ? row?.user?.public_address : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.nft?.title ? row?.nft?.title : 'Not Selected'}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </Dialog>
      {/*Add Model End*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BidMaster);
