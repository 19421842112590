import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertstyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { lazy, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import userService from "../../services/user";
// import history from "../../history";
import * as squadAction from "./actions";
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ "components/ReactTable/ReactTable.js"
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(alertstyles);
const ReportContainer = (props) => {
  const [alert, setAlert] = React.useState(null);
  const [dataList, setData] = React.useState([]);
  const [orgDataList, setOrgData] = React.useState([]);
  const [activeData, setActiveData] = React.useState({});
  const [totalCnt, setTotal] = React.useState(5);
  const [currentPageSize, setPageSize] = React.useState(5);
  const [currentPageNo, setPageNo] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [sortby, setSortBy] = React.useState([]);
  const { register, handleSubmit, reset } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, reset: reset2, formState: { errors2 } } = useForm();
  const [classicModal, setClassicModal] = React.useState(false);
  const [classicModalAdd, setClassicModalAdd] = React.useState(false);
  const classes = useStyles();
  const classesalert = useStylesAlert();
  useEffect(() => {
    (async () => {
      let token = localStorage.getItem('access_token');
      console.log("token ==>", token);
      try{
         await userService.checkToken(token);
      } catch(err){
        if(err?.response?.status == 401){
          history.push("/auth/logout-page")
        }
      }
    })();
  }, []);
  useEffect(() => {
    reload()
}, [])
  const setTableData = (data) => {
    //   console.log("faq contact==>", squads.data.content);
    let dataParsed = JSON.parse(data.data.content);
    if (dataParsed) {
      setOrgData(dataParsed.map((prop, key) => {
       return{ que: prop.que, ans: prop.ans, key}
      }))
      setData(
        dataParsed.map((prop, key) => {
          console.log("prop data ==>", prop);
          return {
            que: prop.que,
            ans: prop.ans.substr(0, 50)+"..." ,
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={async () => {
                    setActiveData({ ...prop, key });
                    setClassicModal(true);
                  }}
                  color="warning"
                  className="edit"
                >
                  <Edit color="primary" />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>{
                      console.log("in delete ==>", dataList, activeData)
                      warningWithConfirmAndCancelMessage({id: key, dataList})
                  }
                  }
                  color="danger"
                  className="remove"
                >
                  <Close />
                </Button>{" "}
              </div>
            ),
          };
        })
      );
    }
  }
  const reload = async () => {
    setActiveData({});
    var data = await userService.getCms("faq");
    setTableData(data)
  };
  const onSubmitDelete = async (datax) => {
      console.log("data =>", datax);

    try {
      props.loaderActions.showLoader();
      var resp = await userService.changeCms({key: "faq", index:datax.id });
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(true, resp.message);
      reload();
      hideAlert();
    } catch (err) {
      console.log("err ==>", err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
      hideAlert();
    }
    console.log("create resp ==>", resp);
  };
  const onSubmit = async (datax) => {
    console.log("data ==>", datax);
    console.log("final ==>", datax, activeData, orgDataList);
    var all = orgDataList;
    all[activeData.key].que = datax.question
    all[activeData.key].ans = datax.answer
    console.log("all ==>", all);
    try {
      props.loaderActions.showLoader();
      var resp = await userService.changeCms({key: "faq", content: JSON.stringify(all)});
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(true, resp.message);
      reload();
      setClassicModal(false);
    } catch (err) {
      console.log("err ==>", err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
    }
    console.log("create resp ==>", resp);
  };
  const onSubmitAdd = async (datax) => {
    try {
        var obj = {
            que: datax.question,
            ans: datax.answer
        }
      props.loaderActions.showLoader();
      var resp = await userService.changeCms({key: "faq", item: obj, add: true});
      props.loaderActions.hideLoader();
      props.toastComponentAction.showSuccessMessage(true, resp.message);
      reload();
      reset2({question: "", answer: ""})
      setClassicModalAdd(false);
    } catch (err) {
      console.log("err ==>", err);
      props.loaderActions.hideLoader();
      props.toastComponentAction.showErrorMessage(
        true,
        err.response.data.message
      );
      reset2({question: "", answer: ""})
    }
    console.log("create resp ==>", resp);
  };
  const warningWithConfirmAndCancelMessage = (datax) => {
      console.log("data ==>", dataList, datax);
    setAlert(   
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onSubmitDelete(datax)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesalert.button + " " + classesalert.success}
        cancelBtnCssClass={classesalert.button + " " + classesalert.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to revert this!
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  return (
    <>
      {alert}
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
        <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={(e) => {
                            setClassicModalAdd(true)
                            setActiveData({})
                            reset2()
                        }}>
                            Add FAQ
              </Button>
                    </div>
                </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>FAQ List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                columns={[
                  {
                    Header: "Question",
                    accessor: "que",
                  },
                  {
                    Header: "Answer",
                    accessor: "ans",
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={dataList}
                total={totalCnt}
                setPageSizeServer={setPageSize}
                setPageNoServer={setPageNo}
                currentPageNo={currentPageNo}
                currentPageSize={currentPageSize}
                filters={filters}
                setFilters={setFilters}
                sortby={sortby}
                setSortBy={setSortBy}
                isServerSide={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Edit Model Start*/}
      <Dialog
        fullWidth
        open={classicModal}
        keepMounted
        onClose={() => {
          setActiveData({});
          setClassicModal(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setActiveData({})
              setClassicModal(false)
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>FAQ Details</p>
        </DialogTitle>
        {Object.keys(activeData).length > 0 ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <CustomInput
                name="question"
                inputrefs={register({ required: true })}
                labelText="Question"
                id="question"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  rows: "3",
                  multiline: true,
                  autoComplete: "off",
                  defaultValue: `${activeData.que}`,
                }}
              />
            
            <CustomInput
                name="answer"
                inputrefs={register({ required: true })}
                labelText="Answer"
                id="answer"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  rows: "5",
                  multiline: true,
                  autoComplete: "off",
                  defaultValue: `${activeData.ans}`,
                }}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        }
              <Button
                 onClick={() => {
                  setActiveData({})
                  setClassicModal(false)
                }}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </form>
        ) : null}
      </Dialog>
      {/*Edit Model End*/}
      {/*Add Model Start*/}
       <Dialog
        fullWidth
        open={classicModalAdd}
        keepMounted
        onClose={() => {
          setActiveData({});
            setClassicModalAdd(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setActiveData({});
              setClassicModalAdd(false);
          }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Add FAQ</p>
        </DialogTitle>
        
          <form onSubmit={handleSubmit2(onSubmitAdd)}>
            <DialogContent>
              <CustomInput
                name="question"
                inputrefs={register2({ required: true })}
                labelText="Question"
                id="question"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  rows: "3",
                  multiline: true,
                  autoComplete: "off"
                }}
              />
            
            <CustomInput
                name="answer"
                inputrefs={register2({ required: true })}
                labelText="Answer"
                id="answer"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: "text",
                  rows: "5",
                  multiline: true,
                  autoComplete: "off"
                }}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter}>
              {
                            activeData ?
                                <Button type="submit" color="transparent">Submit</Button> : ''
                        }
              <Button
                onClick={() => {
                  setActiveData({});
                  setClassicModalAdd(false);
              }}
                color="danger"
                simple
              >
                Close
              </Button>
            </DialogActions>
          </form>
        ) 
      </Dialog>
      {/*Add Model End*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
