import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import * as masterUserAction from "../../redux/masterUserAction";
import * as loginAction from "../Login/actions.js";
import userService from "../../services/user";

const LogoutContainer = props => {
  

    useEffect(() => {
        (async() => {
            let token = localStorage.getItem('access_token');
            if(token){
                await userService.logout();
            }
            localStorage.clear();
            props.loginAction.resetLoginData();
            props.masterUserAction.resetUserData();
            history.push("/auth/login-page");
        })()

    }, []);

    return null;


};

const mapStateToProps = state => {
    return {
     
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loginAction: bindActionCreators(loginAction, dispatch),
        masterUserAction: bindActionCreators(masterUserAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LogoutContainer);
