import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './rootReducer';
import throttle from 'lodash.throttle';

// const configureStore = (initialState = {}) => {
const configureStore = () => {
  const loadState = () => {
    try {
      const serializedState = localStorage.getItem("nfton-state");
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }

  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem("nfton-state", serializedState);
    } catch (err) {
      //Ignore write errors
    }
  }

  const persistedState = loadState();

  const composeEnhancers =
    /* eslint-disable */
    // Deactivate redux devtools if production
    (process.env.NODE_ENV === 'development' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  /* eslint-enable */
  const store = createStore(
    reducers,
    persistedState,
    composeEnhancers(applyMiddleware(thunk))
  );

  store.subscribe(throttle(() => {
    saveState({
      masterUser: store.getState().masterUser
    });
  }, 1000));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      /* eslint-disable */
      const nextRootReducer = require('./rootReducer');
      /* eslint-enable */
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
