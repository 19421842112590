// import { history } from '../redux/history';
import { CONTENT_TYPE } from "utilities/app.js";
import { request } from "utilities/request.js";


function getCommonHeaderDetails(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
  }, data || {});
}
function getCommonHeaderDetailsWithToken(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'Authorization':  getUserToken()
  }, data || {});
}
function getCommonHeaderDetailsWithTokenForApi(data) {
  return Object.assign({}, {
    'Content-Type': CONTENT_TYPE,
    'authorization':  getUserTokenForApi()
  }, data || {});
}
function getUserTokenForApi() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}
function getUserToken() {
  let token = localStorage.getItem('access_token') ? localStorage.getItem('access_token') : "";
  let bearerToken = "";

  if (token)
    bearerToken = token;

  return bearerToken;
}


const getUserList = (data = {}, paginationStr = "") => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/get-user-list?' + paginationStr;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getHistory = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/history/'+data.public_address;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      // console.log("history resp==>", response)
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const editUser = (data = {}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users';
  let headerData = {};
  return request(
    url,  'patch', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const checkToken = (token) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/verify/'+token;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), {},false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const getCms = (type) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/cms?key='+type;
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), {},false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const changeCms = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/cms';
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'patch', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const forgotPassword = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/forgot-password';
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const resetPassword = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/reset-password';
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const changePassword = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/change-password';
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const logout = () => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/logout';
  let headerData = {};
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), {},false
  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

// Clients
const getClients = (data={},str="") => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/get-clients?'+str;
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const createClient = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/create-client';
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const updateClientStatus = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/update-client-status';
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

// Bid
const getBidList = (data={},str="") => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/get-bid-list?'+str;
  let headerData = {};
  console.log("data ==>", data);
  return request(
    url,  'get', getCommonHeaderDetailsWithToken(headerData), data,false

  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const createBid = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/create-bid';
  let headerData = {};
  console.log("data ==>", data);
  return request(url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}
const getBidDetails = (data={}) => {
  let url = process.env.REACT_APP_API_HOST + '/admin/v1/users/get-bid-details';
  let headerData = {};
  console.log("data ==>", data);
  return request(url,  'post', getCommonHeaderDetailsWithToken(headerData), data,false)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw (error);
    });
}

const apiService = {

  getUserList,
  editUser,
  getHistory,
  checkToken,
  getCms,
  changeCms,
  forgotPassword,
  resetPassword,
  changePassword,
  logout,
  getClients,
  createClient,
  updateClientStatus,
  getBidList,
  createBid,
  getBidDetails
};

export default apiService;
