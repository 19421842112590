import apiService from "../../services/apiService";
import userService from "../../services/user";
// import { SET_LOGIN_SUCCESS, SET_USER_DATA, SET_USER_TOKEN, RESET_LOGIN_DATA } from "../../redux/actionTypes";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE_ON_LOGIN, getValue, TOAST_MESSAGE_TIMEOUT } from "../../utilities/app";


export const doForgotPassword = (data) => {
    return (dispatch) => {
        // 1. Calling showLoader() method to display loader...
        dispatch(loaderActions.showLoader());
        return userService.forgotPassword(data)
            .then(result => {
                dispatch(loaderActions.hideLoader());
                if (result.status) {


                    dispatch(toastComponentAction.showSuccessMessage(true, result.message));
                    setTimeout(() => {
                        dispatch(toastComponentAction.showSuccessMessage(false, ""));
                    }, TOAST_MESSAGE_TIMEOUT);


                } else {
                    dispatch(toastComponentAction.showErrorMessage(true, result.message));
                    setTimeout(() => {
                        dispatch(toastComponentAction.showErrorMessage(false, ""));
                    }, TOAST_MESSAGE_TIMEOUT);
                }
            }, error => {
                dispatch(loaderActions.hideLoader());
                dispatch(toastComponentAction.showErrorMessage(true, error.response.data.message));
                setTimeout(() => {
                    dispatch(toastComponentAction.showErrorMessage(false, ""));
                }, TOAST_MESSAGE_TIMEOUT);

            });
    }
};