// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { lazy, useEffect } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "../../history";
import * as loaderActions from "../../redux/loaderAction";
import * as toastComponentAction from "../../redux/toast-componentAction";
import userService from "../../services/user";
// import history from "../../history";
import * as squadAction from "./actions";
const ImageUpload = lazy(() => import(/* webpackChunkName: "image-upload" */ 'components/CustomUpload/ImageUpload.js'));
const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

const useStyles = makeStyles(styles);
const AboutUs = props => {

    const [data, setData] = React.useState([]);
    const [activeData, setActiveData] = React.useState({});
    const { register, handleSubmit, reset } = useForm();
    const [classicModal, setClassicModal] = React.useState(false);
    const [profile_pic, setProfilePic] = React.useState(null);
    const classes = useStyles();
    useEffect(() => {
        (async () => {
            let token = localStorage.getItem('access_token');
            console.log("token ==>", token);
            try {
                await userService.checkToken(token);
            } catch (err) {
                if (err?.response?.status == 401) {
                    history.push("/auth/logout-page")
                }
            }
            var cms = await userService.getCms("about")
            console.log("cms ==>", cms.data);

            setActiveData({ content: cms.data.content, images: cms.data.images });

        })()
    }, [])
    const reload = async () => {

        let token = localStorage.getItem('access_token');
        console.log("token ==>", token);
        try {
            await userService.checkToken(token);
        } catch (err) {
            if (err?.response?.status == 401) {
                history.push("/auth/logout-page")
            }
        }
        var cms = await userService.getCms("about")
        console.log("cms ==>", cms.data);
        setActiveData({ content: cms.data.content, images: cms.data.images });
    }
    const onChange = (editorState) => {
        var data = activeData;
        data.content = editorState
        setActiveData({ ...data })
    }
    const validateFile = (file) => {
        if(file){
            console.log("file ==>", file.size, file.type);
            if(file.size < 5000000 && file.type.includes("image")){
                setProfilePic(file);
            } else {
                props.toastComponentAction.showErrorMessage(true, "The file should be a valid image and have size less than 5MB.");
            }
        }
    }
    const saveChanges = async () => {
        try {
            props.loaderActions.showLoader();
            var formData = new FormData();
            formData.append("key", "about");
            formData.append("content", activeData.content);
            formData.append("images", profile_pic)

            var resp = await userService.changeCms(formData);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showSuccessMessage(true, resp.message)
            reload();
            setClassicModal(false)
        } catch (err) {
            console.log("err ==>", err);
            props.loaderActions.hideLoader();
            props.toastComponentAction.showErrorMessage(true, err.response.data.message)
        }
    }
    return (
        <>
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem></GridItem>
                <GridItem>
                    <div>
                        <Button color="primary" className={classes.marginRight} onClick={saveChanges}>
                            Save Changes
                        </Button>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer
                justify="space-between" // Add it here :)
                container
            >
                <GridItem xs={12}>
                    <Card style={{ height: "100%" }}>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>About Us</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {Object.keys(activeData).length > 0 ?<>
                            <h4 className={classes.cardIconTitle}>Upload Image (Recommended resolution: 630x500)</h4><br />
                                <ImageUpload
                                    defaultPic={activeData.images}
                                    addButtonProps={{
                                        color: "rose",
                                        round: true,
                                    }}
                                    onChangeFile={(file) => {
                                        validateFile(file)
                                    }}
                                    changeButtonProps={{
                                        color: "rose",
                                        round: true,
                                    }}
                                    removeButtonProps={{
                                        color: "danger",
                                        round: true,
                                    }}
                                    button_text="Add Squad Badge"
                                    width="680px"
                                    height="380px"
                                />
                                    <h4 className={classes.cardIconTitle}>Update Content</h4><br />
                                    <ReactQuill theme="snow" value={activeData.content} onChange={onChange} /></>
                                    : null}
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
};

const mapStateToProps = state => {
    return {
        // registration: state.authenticationReducer.registration
    };
};

const mapDispatchToProps = dispatch => {
    return {
        squadAction: bindActionCreators(squadAction, dispatch),
        loaderActions: bindActionCreators(loaderActions, dispatch),
        toastComponentAction: bindActionCreators(toastComponentAction, dispatch)
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
