import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// core components
import FormControlLabel from '@material-ui/core/FormControlLabel';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { DataGrid } from '@material-ui/data-grid';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import moment from 'moment';
import React, { lazy, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history';
import * as loaderActions from '../../redux/loaderAction';
import * as toastComponentAction from '../../redux/toast-componentAction';
import nftService from '../../services/nft';
import userService from '../../services/user';
import settingService from '../../services/setting';
// import history from "../../history";
import * as squadAction from './actions';
const ReactTable = lazy(() =>
  import(
    /* webpackChunkName: "react-table" */ 'components/ReactTable/ReactTable.js'
  )
);
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const useStyles = makeStyles(styles);
const NftContainer = (props) => {
  const [data, setData] = React.useState([]);
  const [totalCnt, setTotal] = React.useState(0);
  const [currentPageSize, setPageSize] = React.useState(5);
  const [currentPageNo, setPageNo] = React.useState(0);
  const [filters, setFilters] = React.useState([]);
  const [sortby, setSortBy] = React.useState([]);
  const [activeData, setActiveData] = React.useState({});
  const [selectedMusic, setSelected] = React.useState({});
  const [blockData, setBlockData] = React.useState({});
  const [classicModalAdd, setClassicModalAdd] = React.useState(false);
  const { register, handleSubmit, reset } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors2 },
  } = useForm();
  const classes = useStyles();

  const setTableData = (data) => {
    if (data && data.data) {
      setTotal(data.total);
      var newList = data.data.map((obj) => ({
        ...obj,
        active: obj.token == selectedMusic.value,
      }));
      console.log('data.data ==>', newList);
      setData(
        newList.map((prop, key) => {
          // console.log("prop ==>", prop);
          return {
            title: prop.title,
            image: (
              // we've added some custom button actions
              <div>
                <img
                  src={prop.image}
                  style={{ width: '277.9', height: '40.42px' }}
                ></img>
              </div>
            ),
            status: (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={prop.status == 1 ? true : false}
                      onChange={(event) => {
                        statusChange(prop.status === 1 ? 2 : 1, prop.id);
                      }}
                      value="checkedA"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                />
              </>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                <Button
                  justIcon
                  round
                  simple
                  onClick={async () => {
                    console.log('details ==>', prop);
                    setActiveData({});
                    setActiveData({ ...prop });
                    setClassicModalAdd(true);
                  }}
                  color="warning"
                  className="edit"
                >
                  <Edit style={{ color: '#7557BB' }} />
                </Button>{' '}
              </div>
            ),
          };
        })
      );
    }
  };
  useEffect(() => {
    let pageStr = `page_no=${currentPageNo}&page_size=${currentPageSize}`;
    if (filters.length > 0 && filters[0].value.trim().length > 0) {
      pageStr =
        pageStr +
        `&searchColumn=${filters[0].id}&searchText=${filters[0].value}`;
    }
    if (sortby.length > 0) {
      pageStr =
        pageStr +
        `&sortBy=${sortby[0].id}&sortDir=${sortby[0].desc ? 'DESC' : 'ASC'}`;
    }
    reload(pageStr);
  }, [currentPageNo, currentPageSize, filters, sortby, selectedMusic]);
  const reload = async (str = '') => {
    var data = await userService.getClients({}, str);
    setTableData(data);
  };
  const statusChange = async (status, client_id) => {
    if (!status) {
      props.toastComponentAction.showErrorMessage(true, 'Status is required');
    } else if (!client_id) {
      props.toastComponentAction.showErrorMessage(
        true,
        'Client id is required'
      );
    } else {
      var final = new FormData();
      final.append('status', status);
      final.append('client_id', client_id);
      try {
        props.loaderActions.showLoader();
        var resp = await userService.updateClientStatus(final);
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message);
        reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
        setClassicModalAdd(false);
        resetModel();
      } catch (err) {
        console.log('err ==>', err);
        props.loaderActions.hideLoader();
        props.toastComponentAction.showErrorMessage(
          true,
          err.response.data.message
        );
      }
      console.log('create resp ==>', resp);
    }
  };
  const onSubmit = async (data) => {
    console.log('data ==>', data);
    if (!activeData.title) {
      props.toastComponentAction.showErrorMessage(true, 'Please enter title');
    } else if (!activeData.image) {
      props.toastComponentAction.showErrorMessage(true, 'Please upload image');
    } else {
      let image =
        activeData && activeData.file_data && activeData.file_data[0]
          ? activeData.file_data[0]
          : null;
      var final = new FormData();
      final.append('title', activeData.title);
      final.append('file', image);
      final.append('file_url', activeData.image);
      final.append('client_id', activeData.id);
      try {
        props.loaderActions.showLoader();
        var resp = await userService.createClient(final);
        props.loaderActions.hideLoader();
        props.toastComponentAction.showSuccessMessage(true, resp.message);
        reload(`page_no=${currentPageNo}&page_size=${currentPageSize}`);
        setClassicModalAdd(false);
        resetModel();
      } catch (err) {
        console.log('err ==>', err);
        props.loaderActions.hideLoader();
        props.toastComponentAction.showErrorMessage(
          true,
          err.response.data.message
        );
      }
      console.log('create resp ==>', resp);
    }
  };
  function handleChange(e) {
    let fileName = e.target.value.substring(
      e.target.value.lastIndexOf('/') + 1,
      e.target.value.lastIndexOf('.')
    );
    if (fileName) {
      setActiveData({
        ...activeData,
        image: URL.createObjectURL(e.target.files[0]),
        file_data: e.target.files,
      });
    }
  }
  const resetModel = () => {
    setActiveData({
      ...activeData,
      title: '',
      image: '',
      file_data: null,
      id: '',
    });
    document.getElementById('custom-form').reset();
  };
  return (
    <>
      <GridContainer
        justify="space-between" // Add it here :)
        container
      >
        <GridItem></GridItem>
        <GridItem>
          <div>
            <Button
              color="primary"
              className={classes.marginRight}
              onClick={(e) => {
                setClassicModalAdd(true);
                resetModel();
              }}
            >
              Add Client
            </Button>
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Clients List</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                filterable
                columns={[
                  {
                    Header: 'Title',
                    accessor: 'title',
                  },
                  {
                    Header: 'Image',
                    accessor: 'image',
                    disableFilters: true,
                  },
                  {
                    Header: 'Status',
                    accessor: 'status',
                    disableFilters: true,
                  },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                  },
                ]}
                data={data}
                total={totalCnt}
                setPageSizeServer={setPageSize}
                setPageNoServer={setPageNo}
                currentPageNo={currentPageNo}
                currentPageSize={currentPageSize}
                filters={filters}
                setFilters={setFilters}
                sortby={sortby}
                setSortBy={setSortBy}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/*Add Model Start*/}
      <Dialog
        fullWidth
        open={classicModalAdd}
        keepMounted
        onClose={() => setClassicModalAdd(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        className="dilogCustomScss"
      >
        <DialogTitle id="form-dialog-title" className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setClassicModalAdd(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <p>Client Details</p>
        </DialogTitle>
        <form
          id="custom-form"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogContent>
            <CustomInput
              name="title"
              labelText="Title"
              id="title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                value: activeData.title,
              }}
              onchange={(e) => {
                setActiveData({
                  ...activeData,
                  title: e.target.value,
                });
              }}
            />
            <input type="file" accept="image/*" onChange={handleChange} />
            {activeData.image && (
              <img src={activeData.image} style={{ height: 200 }} />
            )}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            {activeData && activeData.status !== 2 ? (
              <Button type="submit" color="transparent">
                Submit
              </Button>
            ) : (
              ''
            )}
            <Button
              onClick={() => setClassicModalAdd(false)}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {/*Add Model End*/}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // registration: state.authenticationReducer.registration
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadAction: bindActionCreators(squadAction, dispatch),
    loaderActions: bindActionCreators(loaderActions, dispatch),
    toastComponentAction: bindActionCreators(toastComponentAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NftContainer);
